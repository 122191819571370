
import { createContext, useContext, useState, useEffect } from 'react';

const MyContext = createContext();

export const MyProvider = ({ children }) => {
  const [isToggleSidebar, setIsToggleSidebar] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [orderid,setorderId] = useState(null);
  const [profile_flag,setProfile] = useState();
  const [currentBalanace, setCurrentBalanace] = useState(0);

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'dark') {
      setIsDarkMode(true);
      document.documentElement.classList.add('dark');     
    }

    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsToggleSidebar(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleTheme = () => {  
    setIsDarkMode(!isDarkMode);
    if (!isDarkMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
  };

  return (
  <MyContext.Provider value={{ isToggleSidebar,currentBalanace,setCurrentBalanace, setIsToggleSidebar,profile_flag,setProfile, isDarkMode, toggleTheme ,orderid,setorderId,setIsDarkMode}}>
      {children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => useContext(MyContext);
