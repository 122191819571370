import Redirectroute from "./Redirectroute";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from "./Auth";



const AllRoutes = () => {   
    
    return (
       <AuthProvider>
        <Router>
          <Redirectroute/> 
        </Router>
        </AuthProvider>
    );
};

export default AllRoutes;
