    import React, { useEffect, useState } from 'react';
    import { Dialog, DialogTitle, DialogContent } from '@mui/material';
    import { z } from 'zod';
    import { useForm } from 'react-hook-form';
    import { IoIosCloseCircle } from "react-icons/io";
    import { zodResolver } from '@hookform/resolvers/zod';
    import { useMyContext } from '../../context/MyContext';
    import { FaWarehouse } from "react-icons/fa6";
    import '../../css/custom-scrollbar.css';

    const schema = z.object({
    orderId: z.string()
        .min(1, "Order ID is required")
        .regex(/^\d+$/, "Order ID must be numeric"),
    Name: z.string()
        .min(1, "Name is required")
        .regex(/^[a-zA-Z\s]+$/, "Name must only contain alphabetic characters"),
    email: z.string()
        .email("Invalid email address"),
    phone: z.string()
        .length(10, "Phone number must be exactly 10 digits")
        .regex(/^\d+$/, "Phone number must be numeric"),
    // status: z.string().min(1, "Status is required"),   
    date: z.string().min(1, "Add Date is required"),
    address: z.string().min(1, "Address is required"),
    pincode: z.string()
        .length(6, "Pincode must be exactly 6 characters")
        .regex(/^\d+$/, "Pincode must be numeric"),
    city: z.string()
        .min(1, "City is required")
        .regex(/^[A-Za-z\s]+$/, "City must only contain letters and spaces"),
    state: z.string()
        .min(1, "State is required")
        .regex(/^[A-Za-z\s]+$/, "State must only contain letters and spaces"),
    country: z.string()
        .min(1, "Country is required")
        .regex(/^[A-Za-z\s]+$/, "Country must only contain letters and spaces"),
    registerName: z.string()
        .min(1, "Register Name is required")
        .regex(/^[a-zA-Z\s]+$/, "Register Name must only contain alphabetic characters"),
    returnAddress: z.string().optional(),
    returnPhone: z.string().optional(),
    returnPincode: z.string().optional(),
    returnCity: z.string().optional(),
    returnState: z.string().optional(),
    returnCountry: z.string().optional(),
    waybill: z.string().optional(),
    warehouse_id:z.string().optional(),
    shipment_type:z.string().optional(),
    payment_id:z.string().optional(),
    order:z.string().optional(),
    payment_mode:z.string().optional(),
    products_desc:z.string().optional(),
    hsn_code: z.string().optional(),
    cod_amount: z.string().optional(),
    order_date: z.string().optional(),
    total_amount: z.string().optional(),
    seller_address: z.string().optional(),
    seller_name: z.string().optional(),
    seller_inv: z.string().optional(),
    quantity: z.string().optional(),
    shipment_width: z.string().optional(),
    shipment_height: z.string().optional(),
    weight: z.string().optional(),
    seller_gst_tin:z.string().optional(),
    shipping_mode: z.string().optional(),
    address_type: z.string().optional(),
    pickup_name: z.string().optional(),
    pickup_address:z.string().optional(),
    pickup_city: z.string().optional(),
    pickup_pin_code: z.string().optional(),
    pickup_country:z.string().optional(),
    pickup_phone:z.string().optional(),
    is_ndr:z.string().optional(),
    request_id: z.string().optional(),
    ndr_count: z.string().optional(),
    label: z.string().optional(),
    ready_to_shipment: z.string().optional(),
    });

    const ViewAllShipment = ({ isopen, onClose, shipment }) => {
    const { isDarkMode } = useMyContext();
    const [isEditMode, setIsEditMode] = useState(false);
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: zodResolver(schema),
    });

    useEffect(() => {
        if (shipment) {
        reset({
            order_id: shipment.order_id || '',
            Name: shipment.first_name  + " " +  shipment.last_name || '',
            email: shipment.email || '',
            phone: shipment.phone || '',
            date: shipment.create_date || '',
            address: shipment.address || '',
            pincode: shipment.pincode || '',
            city: shipment.city || '',
            state: shipment.state || '',
            country: shipment.country || '',
            registerName: shipment.name || '',
            returnAddress: shipment.return_address || '',
            returnPhone: shipment.return_phone || '',
            returnPincode: shipment.return_pincode || '',
            returnCity: shipment.return_city || '',
            returnState: shipment.return_state || '',
            returnCountry: shipment.return_country || '',
            waybill:shipment.waybill || '',
            warehouse_id:shipment.warehouse_id || '',
            shipment_type:shipment.shipment_type || '',
            payment_id:shipment.payment_id || '',
            order:shipment.order || '',
            payment_mode:shipment.payment_mode || '',
            products_desc:shipment.products_desc || '',
            hsn_code: shipment.hsn_code || '',
            cod_amount: shipment.cod_amount || '',
            order_date: shipment.order_date || '',
            total_amount:shipment.total_amount || '',
            seller_address: shipment.seller_address || '',
            seller_name: shipment.seller_name || '',
            seller_inv: shipment.seller_inv || '',
            quantity: shipment.quantity || '',
            shipment_width:shipment.shipment_width || '',
            shipment_height:shipment.shipment_height || '',
            weight:shipment.weight*1000 || '',
            seller_gst_tin:shipment.seller_gst_tin || '',
            shipping_mode: shipment.shipping_mode || '',
            address_type: shipment.address_type || '',
            pickup_name: shipment.pickup_name || '',
            pickup_address:shipment.pickup_address || '',
            pickup_city: shipment.pickup_city || '',
            pickup_pin_code: shipment.pickup_pin_code || '',
            pickup_country:shipment.pickup_country || '',
            pickup_phone:shipment.pickup_phone || '',
            is_ndr:shipment.is_ndr || '',
            request_id:shipment.request_id || '',
            ndr_count: shipment.ndr_count || '',
            label: shipment.label || '',
            ready_to_shipment: shipment.ready_to_shipment || '',
        });
        }
    }, [shipment, reset]);

    const onSubmit = (data) => {
        onClose();
    };

    const handleClose = () => {
        setIsEditMode(false);
        onClose();
    };


    return (
        <Dialog open={isopen} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle className="bg-customPurple text-white relative  shadow-custom-light dark:shadow-custom-dark ">
            <h2
            className="text-xl sm:text-2xl font-bold text-white font-sans px-2 py-2 rounded-custom h-[40px] w-full sm:w-full flex items-center justify-center">
            <FaWarehouse className="mr-2" size={24} />
            Shipment Details
            </h2>
            <IoIosCloseCircle
            className="absolute top-2 right-2 cursor-pointer"
            size={32}
            onClick={handleClose}
            />  
        </DialogTitle>

        <DialogContent className="bg-white custom-scrollbar dark:bg-[rgba(1,14,78,0.96)]" style={{ maxHeight: '70vh' }}>
            <div className="p-4 m-4 bg-custom-background shadow-custom-light dark:bg-[rgba(1,14,78,0.96)] dark:shadow-custom-dark dark:text-white rounded-custom-custom">

            <form className="space-y-6 font-sans" onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">OID</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('order_id')}
                    disabled={!isEditMode}
                    />
                    {errors.order_id && <p className="text-red-500 text-sm">{errors.order_id.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Name</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('Name')}
                    disabled={!isEditMode}
                    />
                    {errors.Name && <p className="text-red-500 text-sm">{errors.Name.message}</p>}
                </div>
                </div>

                {/* Additional form fields following the same structure */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Email</label>
                    <input
                    type="email"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('email')}
                    disabled={!isEditMode}
                    />
                    {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Phone</label>
                    <input
                    type="tel"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('phone')}
                    disabled={!isEditMode}
                    />
                    {errors.phone && <p className="text-red-500 text-sm">{errors.phone.message}</p>}
                </div>
                </div>

                {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Status</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('status')}
                    disabled={!isEditMode}
                    />
                    {errors.status && <p className="text-red-500 text-sm">{errors.status.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Add Date</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('date')}
                    disabled={!isEditMode}
                    />
                    {errors.date && <p className="text-red-500 text-sm">{errors.date.message}</p>}
                </div>
                </div> */}

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Address</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('address')}
                    disabled={!isEditMode}
                    />
                    {errors.address && <p className="text-red-500 text-sm">{errors.address.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Pincode</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('pincode')}
                    disabled={!isEditMode}
                    />
                    {errors.pincode && <p className="text-red-500 text-sm">{errors.pincode.message}</p>}
                </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">City</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('city')}
                    disabled={!isEditMode}
                    />
                    {errors.city && <p className="text-red-500 text-sm">{errors.city.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">State</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('state')}
                    disabled={!isEditMode}
                    />
                    {errors.state && <p className="text-red-500 text-sm">{errors.state.message}</p>}
                </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Country</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('country')}
                    disabled={!isEditMode}
                    />
                    {errors.country && <p className="text-red-500 text-sm">{errors.country.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Register Name</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('registerName')}
                    disabled={!isEditMode}
                    />
                    {errors.registerName && <p className="text-red-500 text-sm">{errors.registerName.message}</p>}
                </div>
                </div>

                {/* Optional Return Address fields */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Return Address</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('returnAddress')}
                    disabled={!isEditMode}
                    />
                    {errors.returnAddress && <p className="text-red-500 text-sm">{errors.returnAddress.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Return Phone</label>
                    <input
                    type="tel"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('returnPhone')}
                    disabled={!isEditMode}
                    />
                    {errors.returnPhone && <p className="text-red-500 text-sm">{errors.returnPhone.message}</p>}
                </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Return Pincode</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('returnPincode')}
                    disabled={!isEditMode}
                    />
                    {errors.returnPincode && <p className="text-red-500 text-sm">{errors.returnPincode.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Return City</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('returnCity')}
                    disabled={!isEditMode}
                    />
                    {errors.returnCity && <p className="text-red-500 text-sm">{errors.returnCity.message}</p>}
                </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Return State</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('returnState')}
                    disabled={!isEditMode}
                    />
                    {errors.returnState && <p className="text-red-500 text-sm">{errors.returnState.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Return Country</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('returnCountry')}
                    disabled={!isEditMode}
                    />
                    {errors.returnCountry && <p className="text-red-500 text-sm">{errors.returnCountry.message}</p>}
                </div>





                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Waybill</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('waybill')}
                    disabled={!isEditMode}
                    />
                    {errors.waybill && <p className="text-red-500 text-sm">{errors.waybill.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Warehouse Id</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('warehouse_id')}
                    disabled={!isEditMode}
                    />
                    {errors.warehouse_id && <p className="text-red-500 text-sm">{errors.warehouse_id.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Shipment Type</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('shipment_type')}
                    disabled={!isEditMode}
                    />
                    {errors.shipment_type && <p className="text-red-500 text-sm">{errors.shipment_type.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Payment Id</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('payment_id')}
                    disabled={!isEditMode}
                    />
                    {errors.payment_id && <p className="text-red-500 text-sm">{errors.payment_id.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Order</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('order')}
                    disabled={!isEditMode}
                    />
                    {errors.order && <p className="text-red-500 text-sm">{errors.order.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Payment Mode</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('payment_mode')}
                    disabled={!isEditMode}
                    />
                    {errors.payment_mode && <p className="text-red-500 text-sm">{errors.payment_mode.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Products Desc</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('products_desc')}
                    disabled={!isEditMode}
                    />
                    {errors.products_desc && <p className="text-red-500 text-sm">{errors.products_desc.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Hsn_Code</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('hsn_code')}
                    disabled={!isEditMode}
                    />
                    {errors.hsn_code && <p className="text-red-500 text-sm">{errors.hsn_code.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Cod_Amount</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('cod_amount')}
                    disabled={!isEditMode}
                    />
                    {errors.cod_amount && <p className="text-red-500 text-sm">{errors.cod_amount.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Order Date</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('order_date')}
                    disabled={!isEditMode}
                    />
                    {errors.order_date && <p className="text-red-500 text-sm">{errors.order_date.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Total Amount</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('total_amount')}
                    disabled={!isEditMode}
                    />
                    {errors.total_amount && <p className="text-red-500 text-sm">{errors.total_amount.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Seller Address</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('seller_address')}
                    disabled={!isEditMode}
                    />
                    {errors.seller_address && <p className="text-red-500 text-sm">{errors.seller_address.message}</p>}
                </div>

                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Seller Name</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('seller_name')}
                    disabled={!isEditMode}
                    />
                    {errors.seller_name && <p className="text-red-500 text-sm">{errors.seller_name.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Seller Inv</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('seller_inv')}
                    disabled={!isEditMode}
                    />
                    {errors.seller_inv && <p className="text-red-500 text-sm">{errors.seller_inv.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Quantity</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('quantity')}
                    disabled={!isEditMode}
                    />
                    {errors.quantity && <p className="text-red-500 text-sm">{errors.quantity.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Shipment Width</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('shipment_width')}
                    disabled={!isEditMode}
                    />
                    {errors.shipment_width && <p className="text-red-500 text-sm">{errors.shipment_width.message}</p>}
                </div>

                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Weight (in Gms)</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('weight')}
                    disabled={!isEditMode}
                    />
                    {errors.weight && <p className="text-red-500 text-sm">{errors.weight.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Seller_Gst_Tin</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('seller_gst_tin')}
                    disabled={!isEditMode}
                    />
                    {errors.seller_gst_tin && <p className="text-red-500 text-sm">{errors.seller_gst_tin.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Shipping_Mode</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('shipping_mode')}
                    disabled={!isEditMode}
                    />
                    {errors.shipping_mode && <p className="text-red-500 text-sm">{errors.shipping_mode.message}</p>}
                </div>
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Address Type</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('address_type')}
                    disabled={!isEditMode}
                    />
                    {errors.address_type && <p className="text-red-500 text-sm">{errors.address_type.message}</p>}
                </div>

                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Address Type</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('address_type')}
                    disabled={!isEditMode}
                    />
                    {errors.address_type && <p className="text-red-500 text-sm">{errors.address_type.message}</p>}
                </div>


                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Address Type</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('address_type')}
                    disabled={!isEditMode}
                    />
                    {errors.address_type && <p className="text-red-500 text-sm">{errors.address_type.message}</p>}
                </div>

  
                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Pickup_Name</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('pickup_name')}
                    disabled={!isEditMode}
                    />
                    {errors.pickup_name && <p className="text-red-500 text-sm">{errors.pickup_name.message}</p>}
                </div>

                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Pickup_Address</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('pickup_address')}
                    disabled={!isEditMode}
                    />
                    {errors.pickup_address && <p className="text-red-500 text-sm">{errors.pickup_address.message}</p>}
                </div>

                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Pickup_City</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('pickup_city')}
                    disabled={!isEditMode}
                    />
                    {errors.pickup_city && <p className="text-red-500 text-sm">{errors.pickup_city.message}</p>}
                </div>

                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Pickup_Pin_Code</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('pickup_pin_code')}
                    disabled={!isEditMode}
                    />
                    {errors.pickup_pin_code && <p className="text-red-500 text-sm">{errors.pickup_pin_code.message}</p>}
                </div>

                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Pickup_Country</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('pickup_country')}
                    disabled={!isEditMode}
                    />
                    {errors.pickup_country && <p className="text-red-500 text-sm">{errors.pickup_country.message}</p>}
                </div>


                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Pickup_Phone</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('pickup_phone')}
                    disabled={!isEditMode}
                    />
                    {errors.pickup_phone && <p className="text-red-500 text-sm">{errors.pickup_phone.message}</p>}
                </div>

                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Is_Ndr</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('is_ndr')}
                    disabled={!isEditMode}
                    />
                    {errors.is_ndr && <p className="text-red-500 text-sm">{errors.is_ndr.message}</p>}
                </div>

                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Request_Id</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('request_id')}
                    disabled={!isEditMode}
                    />
                    {errors.request_id && <p className="text-red-500 text-sm">{errors.request_id.message}</p>}
                </div>

                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Ndr_Count</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('ndr_count')}
                    disabled={!isEditMode}
                    />
                    {errors.ndr_count && <p className="text-red-500 text-sm">{errors.ndr_count.message}</p>}
                </div>

                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Label</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('label')}
                    disabled={!isEditMode}
                    />
                    {errors.label && <p className="text-red-500 text-sm">{errors.label.message}</p>}
                </div>

                <div>
                    <label className="block text-sm ml-2 font-bold text-black dark:text-white">Ready_To_Shipment</label>
                    <input
                    type="text"
                    className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                    {...register('ready_to_shipment')}
                    disabled={!isEditMode}
                    />
                    {errors.ready_to_shipment && <p className="text-red-500 text-sm">{errors.ready_to_shipment.message}</p>}
                </div>

                </div>

                <button
                onClick={() => setIsEditMode(!isEditMode)}
                className="bg-customPurple text-white py-2 px-4 rounded-custom-custom mb-4 hover:bg-customPurpleHover focus:outline-none focus:ring-2 focus:ring-customPurple focus:ring-opacity-50"
                >
                {isEditMode ? 'Cancel' : 'Edit'}
                </button>


                {isEditMode && (
                <button
                    type="submit"
                    className="bg-customPurple text-white py-2 px-4 md:ml-8 rounded-custom-custom mt-4 hover:bg-customPurpleHover focus:outline-none focus:ring-2 focus:ring-customPurple focus:ring-opacity-50"
                >
                    Save
                </button>
                )}
            </form>
            </div>
        </DialogContent>
        </Dialog>
    );
    };

    export default ViewAllShipment;
