import React, { useState, useEffect } from 'react';
import { IoMdEye } from 'react-icons/io';
import { CiEdit } from 'react-icons/ci';
import { MdDelete } from "react-icons/md";
import { Button, Pagination } from '@mui/material';
import { json, useNavigate } from 'react-router-dom';
import ViewWarehouse from '../../../component/models/ViewWarehouse';
import { useMyContext } from '../../../context/MyContext';
import Sidebar from '../../../component/sidebar/sidebar';
import NavBar from '../../../component/navBar/navBar';
import PaginationItem from '@mui/material/PaginationItem';
import '../../../css/custom-scrollbar.css';
import { LuWarehouse } from "react-icons/lu";
import axios from 'axios';
import toast from 'react-hot-toast';
import { Base_Url, deletewarehouse, Warehouselist } from '../../../config/config';

const AllWarehouses = () => {
  const [totalOrders, setTotalOrders] = useState(0);
  const { isDarkMode } = useMyContext();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  // const [selectAll, setSelectAll] = useState(false);
  const { isToggleSidebar } = useMyContext();
  const [warehouses, setWarehouses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);
  const itemsPerPage = 8;
  const navigate = useNavigate();
  

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10); 

  useEffect(() => {
    fetchWarehouses()
  }, [page, limit]); 


  const fetchWarehouses = async () => {
    const token = localStorage.getItem('token');
    const cusid = localStorage.getItem('cus_id');
    try {
      const pageNumber = Number(page) || 1;
      const limitNumber = Number(limit) || 10;
      const urlWarehouse = `${Base_Url}${Warehouselist}?page=${pageNumber}&limit=${limitNumber}`
      const response = await fetch(urlWarehouse, {
        method: 'GET',
        headers: {
        'token': token,
            'cus_id': cusid,
            'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      if (result.status === "SUCCESS" && Array.isArray(result.data)) {
        setWarehouses(result.data);
        setTotalOrders(result.pagination.totalItems || 0);
      } else {
        console.error('Unexpected response format:', result);
      }
    } catch (error) {
      console.error('Error fetching warehouse data:', error);
    }
  };





  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(1);
  };
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = warehouses.slice(indexOfFirstItem, indexOfLastItem);

  // const handlePageChange = (event, value) => {
  //   setCurrentPage(value);
  // };

  const handleViewClick = (warehouse) => {
    setSelectedWarehouse(warehouse);
    setIsPopupOpen(true);
  };

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const handleNavigation = () => {
    navigate('/dashboard');
  };

  const handleDelete = async (id) => {
    const urldelete = Base_Url + deletewarehouse;
    try {
      const token = localStorage.getItem('token');
      const cusid = localStorage.getItem('cus_id');
      const data = {
        warehouseId: id
      };
      const response = await axios.post(urldelete, data, {
        headers: {
          token,
          cus_id: cusid,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });
  
      if (response.data.status === "SUCCESS") {
        setWarehouses((prevWarehouses) => prevWarehouses.filter(warehouse => warehouse.id !== id));
        setTotalOrders((prev) => prev - 1);
      } else {
        toast.error('Unexpected response format: ' + response.data.message);
      }
    } catch (error) {
      toast.error('Error deleting warehouse: ' + error.message);
    }
  };

  return (
    <>
      <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
        <div className="z-10">
          <NavBar />
        </div>

        <div className="flex flex-1 overflow-hidden">
          {isToggleSidebar && (
            <div className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
            style={
              window.innerWidth < 768 
                ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                : {}
            }>
              <Sidebar />
            </div>
          )}

          <div className={`shadow-custom-light overflow-x-hidden ml-4 mt-6 mb-6 mr-6 w-full rounded-custom overflow-y-auto dark:shadow-custom-dark p-4 ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)] text-white' : ''}`}>

            <button onClick={handleNavigation}
              className="text-xl w-full sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px]  sm:w-full flex items-center justify-center">
              <LuWarehouse className="mr-2" size={24} />
              All WareHouses
            </button>

            <div className="overflow-x-auto">
              <table className="min-w-full w-full table-auto">
                <thead className="bg-[#0540a6dd] text-white text-[14px]">
                  <tr className='bg-[#0540a6dd] text-white border border-[#aba7a7]'> 
                    <th className="p-2 text-left w-1/12">ID</th>
                    <th className="p-2 text-left w-2/12">Name</th>
                    <th className="p-2 text-left w-3/12">Email</th>
                    <th className="p-2 text-left w-2/12">Phone</th>
                    <th className="p-2 text-left w-2/12">City</th>
                    <th className="p-2 text-left w-2/12">State</th>
                    <th className="p-2 text-left w-2/12">Country</th>
                    <th className="p-2 text-left w-2/12 pl-12">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {warehouses.reverse().map((warehouse, index) => (
                    <tr key={warehouse.id} className="w-full">
                      <td className="p-2">{warehouse.customer_id}</td>
                      <td className="p-2">{warehouse.name}</td>
                      <td className="p-2">{warehouse.email}</td>
                      <td className="p-2">{warehouse.phone}</td>
                      <td className="p-2">{warehouse.city}</td>
                      <td className="p-2">{warehouse.state}</td>
                      <td className="p-2">{warehouse.country}</td>
                      <td className="p-2">
                        <div className="flex space-x-2">
                          <Button variant="contained" size="small" color="primary" onClick={() => handleViewClick(warehouse)}>
                            <IoMdEye />
                          </Button>
                          <Button variant="contained" size="small" color="error" onClick={() => handleDelete(warehouse.id)}>
                            <MdDelete />
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>


            <div className="mt-4 flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
                <span className="text-sm text-gray-700 font-bold dark:text-gray-300">{`Total Warehouse: ${totalOrders}`}</span>
                <select
                  id="limit"
                  value={limit}
                  onChange={handleLimitChange}
                  className="border rounded p-1 ml-[480px] text-sm bg-white dark:bg-[rgba(1,14,78,0.96)] dark:text-gray-200"
                >
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  {/* <option value={50}>50</option> */}
                  <option value={totalOrders}>All</option>
                </select>
                <Pagination
                  count={Math.ceil(totalOrders / limit)}
                  page={page}
                  onChange={handlePaginationChange}
                  variant="outlined"
                  shape="rounded"
                  className="pagination"
                  size="small"
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      className={`mx-1 rounded-md ${item.selected ? 'bg-customPurple text-white' : 'text-gray-700 dark:text-gray-300'} hover:bg-purple-700 hover:text-white`}
                    />
                  )}
                />
              </div>
          </div>
        </div>

        <ViewWarehouse isopen={isPopupOpen} onClose={handleClose} warehouse={selectedWarehouse} />
      </div>
    </>
  );
};

export default AllWarehouses;
