import React from 'react';
import { Upload } from 'lucide-react';
import NavBar from '../../../src/component/navBar/navBar';
import Sidebar from '../../component/sidebar/sidebar';
import { useMyContext } from '../../context/MyContext';
 import '../../css/custom-scrollbar.css';


const AddCustomer = () => {
  const {isToggleSidebar,isDarkMode} = useMyContext();
  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
    <div className="z-10">
      <NavBar />
    </div>

    <div className="flex flex-1 overflow-hidden">
      {isToggleSidebar && (
        <div
          className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
          style={
            window.innerWidth < 768 
              ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
              : {}
          } >
          <Sidebar />
        </div>
      )}
    <div className="max-w-full mx-auto ml-2 mb-2 p-6 bg-white mt-4 dark:bg-[rgba(1,14,78,0.96)] dark:text-white rounded-custom border-gray-300 shadow-custom-light dark:shadow-custom-dark rounded-custom overflow-y-auto custom-scrollbar mr-2">
      <h2 className="text-2xl w-full text-center font-bold mb-6 bg-customPurple text-white py-2 px-4 rounded-custom inline-block">
        Onboard A New Customer
      </h2>
      
      <form className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-4">
          <div>
            <label htmlFor="customerName" className="block text-sm font-medium  dark:text-white font-sans text-gray-700">Customer Name</label>
            <input type="text" id="customerName" className="mt-2 p-2 dark:bg-[rgba(1,14,78,0.96)] dark:text-white focus:outline-none focus:shadow-outline block w-full rounded-custom border-gray-300 shadow-custom-light dark:shadow-custom-dark "/>
          </div>
          
          <div>
            <label htmlFor="email" className="block text-sm font-medium font-sans dark:text-white text-gray-700">Email</label>
            <input type="email" id="email" className="mt-2 focus:outline-none dark:bg-[rgba(1,14,78,0.96)] dark:text-white focus:shadow-outline block p-2 w-full rounded-custom border-gray-300 shadow-custom-light dark:shadow-custom-dark " />
          </div>
          
          <div>
            <label htmlFor="phone" className="block text-sm font-medium font-sans dark:text-white text-gray-700">Phone</label>
            <input type="tel" id="phone" className="mt-2 block w-full dark:bg-[rgba(1,14,78,0.96)] dark:text-white focus:outline-none focus:shadow-outline p-2 rounded-custom border-gray-300 shadow-custom-light dark:shadow-custom-dark " />
          </div>
          
          <div>
            <label htmlFor="companyName" className="block text-sm font-medium font-sans dark:text-white  text-gray-700">Company Name</label>
            <input type="text" id="companyName" className="mt-2 p-2  dark:bg-[rgba(1,14,78,0.96)] dark:text-white focus:outline-none focus:shadow-outline block w-full rounded-custom border-gray-300 shadow-custom-light dark:shadow-custom-dark " />
          </div>
          
          <div>
            <label htmlFor="address" className="block text-sm font-medium font-sans dark:text-white text-gray-700">Address</label>
            <textarea id="address" rows={3} className="mt-2 p-2 dark:bg-[rgba(1,14,78,0.96)] dark:text-white focus:outline-none focus:shadow-outline block w-full rounded-custom border-gray-300 shadow-custom-light dark:shadow-custom-dark "></textarea>
          </div>
          
          <div>
            <h3 className="text-lg font-medium font-sans dark:bg-[rgba(1,14,78,0.96)] dark:text-white text-gray-700 mb-2">Bank Details</h3>
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label htmlFor="bankName" className="block text-sm font-medium  dark:text-white font-sans text-gray-700">Bank Name</label>
                <input type="text" id="bankName" className="mt-2 p-2 dark:bg-[rgba(1,14,78,0.96)] dark:text-white block w-full focus:outline-none focus:shadow-outline rounded-custom border-gray-300 shadow-custom-light dark:shadow-custom-dark " />
              </div>
              <div>
                <label htmlFor="accountNumber" className="block text-sm font-medium  dark:text-white font-sans text-gray-700">Account No</label>
                <input type="text" id="accountNumber" className="mt-2 p-2 dark:bg-[rgba(1,14,78,0.96)] dark:text-white block w-full focus:outline-none focus:shadow-outline rounded-custom border-gray-300 shadow-custom-light dark:shadow-custom-dark " />
              </div>
              <div>
                <label htmlFor="ifscCode" className="block text-sm font-medium font-sans dark:text-white text-gray-700">IFSC Code</label>
                <input type="text" id="ifscCode" className="mt-2 block dark:bg-[rgba(1,14,78,0.96)] dark:text-white p-2 w-full focus:outline-none focus:shadow-outline rounded-custom border-gray-300 shadow-custom-light dark:shadow-custom-dark " />
              </div>
            </div>
          </div>
          
          <div>
            <button type="button" className="inline-flex items-center dark:bg-[rgba(1,14,78,0.96)] dark:text-white px-4 py-2 border border-transparent text-sm font-medium font-sans rounded-custom shadow-custom-light dark:shadow-custom-dark text-white bg-customPurple hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <Upload className="mr-2 h-5 w-5" />
              Upload Cancel Cheque
            </button>
          </div>
        </div>
        
        <div className="space-y-4">
          <div>
            <label htmlFor="companyType" className="block text-sm font-medium dark:text-white text-gray-700">Company Type</label>
            <select id="companyType" className="mt-2 block w-full dark:bg-[rgba(1,14,78,0.96)] dark:text-white p-2  focus:outline-none focus:shadow-outline rounded-custom border-gray-300 shadow-custom-light dark:shadow-custom-dark ">
              <option>Select Company Type</option>
            </select>
          </div>
          
          <div>
            <label htmlFor="gstin" className="block text-sm font-medium dark:text-white  text-gray-700">GSTIN</label>
            <input type="text" id="gstin" className="mt-2 dark:bg-[rgba(1,14,78,0.96)] dark:text-white block w-full p-2 focus:outline-none focus:shadow-outline rounded-custom border-gray-300 shadow-custom-light dark:shadow-custom-dark " />
          </div>
          
          <div>
            <label htmlFor="requiredDocument" className="block text-sm font-medium font-sans dark:text-white  text-gray-700">Required Document</label>
            <select id="requiredDocument" className="mt-2 dark:bg-[rgba(1,14,78,0.96)] dark:text-white block w-full p-2 focus:outline-none focus:shadow-outline rounded-custom border-gray-300 shadow-custom-light dark:shadow-custom-dark ">
              <option>Select Valid Document</option>
            </select>
          </div>
          
          <div>
            <button type="button" className="inline-flex items-center px-4  py-2 border border-transparent text-sm font-medium font-sans rounded-custom shadow-custom-light dark:shadow-custom-dark text-white bg-customPurple hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <Upload className="mr-2 h-5 w-5" />
              Upload file
            </button>
          </div>
          
          <div>
            <label htmlFor="kycNumber" className="block text-sm font-medium dark:text-white font-sans text-gray-700">KYC Number</label>
            <input type="text" id="kycNumber" className="mt-2 block dark:bg-[rgba(1,14,78,0.96)] dark:text-white w-full focus:outline-none focus:shadow-outline  p-2 rounded-custom border-gray-300 shadow-custom-light dark:shadow-custom-dark " />
          </div>
          
          <div>
            <label className="block text-sm font-medium dark:text-white text-gray-700">Select Docs</label>
            <div className="mt-2 space-x-4">
              <label className="inline-flex items-center">
                <input type="radio" className="form-radio dark:bg-[rgba(1,14,78,0.96)] dark:text-white  p-1 text-indigo-600" name="docs" value="pan" />
                <span className="ml-2">Pan</span>
              </label>
              <label className="inline-flex items-center">
                <input type="radio" className="form-radio p-1 dark:bg-[rgba(1,14,78,0.96)] dark:text-white text-indigo-600" name="docs" value="aadhar" />
                <span className="ml-2">Aadhar</span>
              </label>
            </div>
          </div>
          
          <div>
            <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium  font-sans rounded-custom shadow-custom-light dark:shadow-custom-dark text-white bg-customPurple  hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <Upload className="mr-2 h-5 w-5" />
              Upload file
            </button>
          </div>
          
          <div>
            <button type="submit" className="w-full inline-flex mt-6 justify-center py-2 px-4 border border-transparent shadow-custom-light dark:shadow-custom-dark text-sm font-medium font-sans rounded-custom text-white bg-customPurple hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              SUBMIT
            </button>
          </div>
        </div>
      </form>
    </div>
    </div>
    </div>
  );
};

export default AddCustomer;