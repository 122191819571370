import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import LoginPage from '../pages/Auth/login';
import SignUpPage from '../pages/Auth/singup';
import DashBoard from '../pages/dashborad/Dashboard';
import ReverseShipment from '../pages/shipment/reverse/reverseShipment';
import ForwordShipment from '../pages/shipment/forword/forwordShipment';
import PickupRequest from '../pages/shipment/pickuprequest/pickupRequuest';
import ShipmentTracking from '../pages/shipment/tracking/shipmentTracking';
import AddWarehouse from '../pages/warehouse/addwarehouse/addWarehouse';
import AllWarehouses from '../pages/warehouse/allWarehouse/allwarehouse';
import NDRDashboard from '../pages/disputes/NDR/ndr';
import RTODashboard from '../pages/disputes/RTO/rto';
import WeightDisputeDashboard from '../pages/disputes/weightdisputes/weightDisputes';
import PageRemittances from '../pages/payments/remmitance/Remmitance';
import PageInvoices from '../pages/payments/invoice/Invoice';
import WalletRecharge from '../pages/payments/walletReachrge/Walletrecharge';
import PaymentHistory from '../pages/payments/payment history/Paymenthistory';
import ShippingCharges from '../pages/payments/shippingCharges/ShippingCharges';
import TopupHistory from '../pages/payments/topuphistory/topupHistory';
import LostDamageClaims from '../pages/risk/LostandDamages';
import ReportsDropdown from '../pages/reports/Reports';
import RateCalculator from '../pages/settings/rateCalculator/RateCalculator';
import KYCPending from '../pages/customer/kycPending';
import AllShipmemt from '../pages/shipment/AllShipment/AllShipment';
import Protectedroute from './Protectedroute';
import { useAuth } from './Auth';
import UpadteKycDashboard from '../pages/updateKYC/updateKYC';
import PincodeService from '../pages/settings/servicablePincodes/pincodes';
import RateCard from '../pages/settings/rateCard/rateCard';
import UploadPincodeComponent from '../pages/settings/uploadPincode/uploadPincode';
import SetRate from '../pages/settings/setRate/setRate';
import AddCustomer from '../pages/customer/addCustomer';
import ProfilePage from '../pages/profile/profilePage';
import ViewAllCustomer from '../pages/customer/ViewAllCustomer';
import Support from '../pages/Help/help';
import Services from '../../src/pages/Services/Services'
import PaymentSuccess from '../pages/payments/paymnetSucess/paymentSuccess';

export default function Redirectroute() {
  const { isAuthenticated, loading } = useAuth(); 
  const location = useLocation(); 
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      {/* Public Routes */}
      {!isAuthenticated ? (
        <>
          <Route path="/register" element={<SignUpPage />} />
          <Route path="/" element={<LoginPage />} />
          {/* Redirect any unauthorized users to login */}
          <Route path="*" element={<Navigate to="/" state={{ from: location }} />} />
        </>
      ) : (
        // Protected Routes - Only accessible when authenticated
        <Route element={<Protectedroute />}>
          <Route path="/updateKYC" element={<UpadteKycDashboard />} />
          <Route path="/dashboard" element={<DashBoard />} />
          <Route path="/shipments/forward" element={<ForwordShipment />} />
          <Route path="/shipments/reverse" element={<ReverseShipment />} />
          <Route path="/shipments/pickuprequest" element={<PickupRequest />} />
          <Route path="/shipments/tracking" element={<ShipmentTracking />} />
          <Route path="/shipments/all" element={<AllShipmemt />} />
          <Route path="/warehouse/add" element={<AddWarehouse />} />
          <Route path="/warehouse/all" element={<AllWarehouses />} />
          <Route path="/disputes/NDR" element={<NDRDashboard />} />
          <Route path="/disputes/RTO" element={<RTODashboard />} />
          <Route path="/disputes/weight" element={<WeightDisputeDashboard />} />
          <Route path="/payments/remmitance" element={<PageRemittances />} />
          <Route path="/payments/invoice" element={<PageInvoices />} />
          <Route path="/payments/walletbalance" element={<WalletRecharge />} />
          <Route path="/payments/history" element={<PaymentHistory />} />
          <Route path="/payments/shippingCharges" element={<ShippingCharges />} />
          <Route path="/payments/topupHistory" element={<TopupHistory />} />
          <Route path="/risk/claims/lost&damages" element={<LostDamageClaims />} />
          <Route path="/reports" element={<ReportsDropdown />} />
          <Route path="/settings/rateCalculator" element={<RateCalculator />} />
          <Route path="/settings/pincode" element={<PincodeService />} />
          <Route path="/settings/rateCard" element={<RateCard />} />
          <Route path="/settings/addPincode" element={<UploadPincodeComponent />} />
          <Route path="/settings/setRate" element={<SetRate />} />
          <Route path="/customers/viewCustomer" element={<ViewAllCustomer />} />
          <Route path="/customers/addCustomer" element={<AddCustomer />} />
          <Route path="/customers/kycPending" element={<KYCPending />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/help" element={<Support />} />
          <Route path="/paymentSuccess" element={<PaymentSuccess />} />
          <Route path="/services" element={<Services />} />
          {/* Redirect any invalid paths to the current page or dashboard */}
          <Route path="*" element={<Navigate to={'/dashboard'} />} />
        </Route>
      )}
    </Routes>
  );
}

    