import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useState, useEffect } from 'react';
import { MdCallMade } from "react-icons/md";
import NavBar from '../../../component/navBar/navBar';
import Select from "react-dropdown-select";
import Sidebar from '../../../component/sidebar/sidebar';
import { useMyContext } from '../../../context/MyContext';
import { Base_Url, pickupRequest, Warehouselist } from '../../../config/config';
import {toast} from 'react-hot-toast';
import Spinner from '../../../component/spinner/spinner';

// Define the validation schema with Zod
const schema = z.object({
  pickupLocation:z.string().optional(),
  date: z.string().min(1, { message: "Date is required" }),
  pickupTiming: z.string()
    .min(1, { message: "Pickup timing is required" })
    .regex(/^([01]\d|2[0-3]):([0-5]\d)$/, { message: "Invalid time format, must be HH:MM" }),
});

export default function PickupRequest() {
  const [wareHouseList, setWareHouseList] = useState([]);
  const { isDarkMode, isToggleSidebar } = useMyContext();
  const [wareHouseId, setWareHouseId] = useState('');
  const [isSubmitting,IsSetSubmitting] = useState(false);

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: zodResolver(schema)
  });

  useEffect(() => {
    fetchWareHouseList();
  }, []);

  const fetchWareHouseList = async () => {
    const token = localStorage.getItem('token');
    const cusid = localStorage.getItem('cus_id');

    const headers = new Headers({
      'token': token,
      'cus_id': cusid,
      'Content-Type': 'application/json'
    });

    try {
      const response = await fetch(Base_Url + Warehouselist, {
        method: 'GET',
        headers: headers
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const transformedData = data.data.map(warehouse => ({
        value: warehouse.id,
        label: warehouse.name
      }));
      setWareHouseList(transformedData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSelectChange = (selected) => {
    if (selected.length > 0) {
      const selectedItem = selected[0];
      setWareHouseId(selectedItem.value);
    }
  };

  const onSubmit = async (data) => {
    IsSetSubmitting(true); 
    
    const currentDate = new Date();
    const selectedDate = new Date(data.date);
    
    const selectedTimeParts = data.pickupTiming.split(':');
    const selectedTime = new Date();
    selectedTime.setHours(selectedTimeParts[0], selectedTimeParts[1]);

    if (
      selectedDate.setHours(0, 0, 0, 0) === currentDate.setHours(0, 0, 0, 0) && 
      selectedTime.getHours() >= 13  
    ) {
      toast.error('You cannot create a request for same day after 1 PM.');
      IsSetSubmitting(false);
      return; 
    }
     if (selectedDate.setHours(0, 0, 0, 0) < currentDate.setHours(0, 0, 0, 0)) {
      toast.error('You cannot create a request for a past date.');
      IsSetSubmitting(false);
      return; 
    }
  
    const token = localStorage.getItem('token');
    const cusid = localStorage.getItem('cus_id');
    const payload = {
      warehouse_id: wareHouseId, 
      order_date: data.date,
      time: data.pickupTiming
    };
    
    try {
      const response = await fetch(Base_Url + pickupRequest, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': token,
          'cus_id': cusid,
        },
        body: JSON.stringify(payload)
      });
  
      const responseData = await response.json();
      if (responseData.status === "SUCCESS") {
        toast.success("Pickup Request successfull");
        IsSetSubmitting(false);
        reset();
      } else {
        toast.error(responseData.message.prepaid || responseData.message.error ||responseData.message.pickup_time || responseData.message.detail  || "Something went wrong");
        IsSetSubmitting(false);
      }
    } catch (error) {
      console.error('Error:', error);
      IsSetSubmitting(false);
    }
  };
  

  
  return (
    <>
      <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
        <div className="z-10">
          <NavBar />
        </div>
        <div className="flex flex-1 overflow-hidden">
          {isToggleSidebar && (
            <div
              className={`fixed z-20 h-full w-64 md:relative duration-300 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
              style={window.innerWidth < 768 ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' } : {}}>
              <Sidebar />
            </div>
          )}
          <div className="w-full mt-6 ml-4 mr-4 shadow-custom-light bg-white rounded-custom md:mt-2 md:mr-2 md:ml-2 md:mb-2 dark:shadow-custom-dark dark:bg-[rgba(1,14,78,0.96)]">
            <div className={`wrapper-class mt-12 mr-4 ml-4 mb-24 max-w-full mx-auto md:mx-0 md:mt-2 md:pl-40 md:pt-8 md:pb-8 border-gray-200 font-sans bg-transparent ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white" : ""}`}>
              <div className="max-w-full md:max-w-[60%] mx-auto md:mx-0 mt-2 p-6 border-gray-200 md:ml-8 font-sans shadow-custom-light dark:shadow-custom-dark rounded-custom">
                <h2 className="text-2xl font-semibold mb-6 bg-customPurple text-white py-2 px-4 rounded-t-lg text-center">
                  Pickup Request Creation
                </h2>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div>
                    <label className="block text-sm font-medium mb-1">Select Pickup Location</label>
                    <Select options={wareHouseList} onChange={handleSelectChange} />
                    {errors.pickupLocation && <p className="text-red-500 text-xs mt-1">{errors.pickupLocation.message}</p>}
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">Select Date</label>
                    <input
                      type="date"
                      {...register('date')}
                      className={`p-2 appearance-none border w-full focus:outline-none focus:shadow-outline shadow-custom-light rounded-custom ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white dark:shadow-custom-dark" : ""}`}
                    />
                    {errors.date && <p className="text-red-500 text-xs mt-1">{errors.date.message}</p>}
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1">PickUp Timing</label>
                    <input
                      type="time"
                      {...register('pickupTiming')}
                      className={`p-2 appearance-none border w-full focus:outline-none focus:shadow-outline shadow-custom-light rounded-custom ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] text-white dark:shadow-custom-dark" : ""}`}
                      placeholder="pickupTiming"
                    />
                    {errors.pickupTiming && <p className="text-red-500 text-xs mt-1">{errors.pickupTiming.message}</p>}
                  </div>
                  <button
                    type="submit"
                    className="w-full mt-6 bg-customPurple text-white py-2 px-4 rounded-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-customPurple focus:ring-opacity-50 flex items-center justify-center"
                  >
                    {/* Create Request */}
                    { isSubmitting ? <Spinner/> : "Create Request"}
                     <MdCallMade className="ml-2" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
