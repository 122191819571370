import { useState, useEffect } from 'react';
import '../../../css/custom-scrollbar.css';
import { useMyContext } from '../../../context/MyContext';
import NavBar from '../../../component/navBar/navBar';
import Sidebar from '../../../component/sidebar/sidebar';
import { Button, Pagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PaginationItem from '@mui/material/PaginationItem';
import { MdDashboard } from 'react-icons/md';
import { ndrList, Base_Url, ndr, Reattempt,pendingApi,Return,ndrStatusList} from '../../../config/config';
import { toast } from 'react-hot-toast';

export default function NDRDashboard() {
  const [activeTab, setActiveTab] = useState('Action Pending');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [ndrListData, setNdrListData] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const navigate = useNavigate();
  const { isDarkMode, isToggleSidebar } = useMyContext();
  const [pagination, setPagination] = useState({
    'Action Pending': 1,
    'Action Taken': 1,
    'RTO Delivered': 1,
    'All Details': 1, 
  });

  const tabs = ['Action Pending', 'Action Taken', 'RTO Delivered', 'All Details'];

  const renderTableHeaders = () => {
    const commonHeaders = ['ID', 'AWB No.', 'Customer Info', 'Value', 'Last Updated',];
    if (activeTab === 'Action Pending'  || 'All Details') {
      return [...commonHeaders, 'Action'];
    }
    return commonHeaders;
  };

  const token = localStorage.getItem('token');
  const cusid = localStorage.getItem('cus_id');

  
useEffect(() => {
    fetchNdrListByCurrentTab();
 
}, [activeTab, pagination[activeTab], limit]); 

const fetchNdrListByCurrentTab = async () => {
  const pageNumber = pagination[activeTab];
  const limitNumber = Number(limit) || 10;

  try {
    let data = [];
    let totalPage = 0;

    if (activeTab === 'Action Pending') {
      const url = `${Base_Url}ndrStatus/${pendingApi}?page=${pageNumber}&limit=${limitNumber}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          token: token,
          cus_id: cusid || '',
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result && result.data) {
        data = result.data;
        totalPage = result.pagination.totalItems || 0;
      } else {
        toast.error(result.message);
      }
    } else if (activeTab === 'Action Taken') {
      const url = `${Base_Url}${ndrStatusList}?page=${pageNumber}&limit=${limitNumber}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          token: token,
          cus_id: cusid || '',
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      if (result && result.data) {
        data = result.data;
        totalPage = result.pagination.totalItems || 0;
      } else {
        toast.error(result.message);
      }
    }
     else if (activeTab === "All Details") {
      const url = `${Base_Url}${ndrList}?page=${pageNumber}&limit=${limitNumber}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          token: token,
          cus_id: cusid || '',
          'Content-Type': 'application/json',
        },
      });
      debugger
      const result = await response.json();
      if (result && result.data) {
        data = result.data;
        totalPage = result.pagination.totalItems || 0;
      } else {
        toast.error(result.message);
      }
    }
    else if (activeTab === 'RTO Delivered') {
      const url = `${Base_Url}${ndrList}?page=${pageNumber}&limit=${limitNumber}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          token: token,
          cus_id: cusid || '',
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();
      debugger
      if (result && result.data) {
        data = result.data.filter(rto=>rto.action ==="Return");
        // totalPage = result.pagination.totalItems || 0;
        totalPage = result.data.filter(rto=>rto.action ==="Return").length || 0;
      } else {
        toast.error(result.message);
      }
    }
    setNdrListData(data);
    setTotalOrders(totalPage);
  } catch (error) {
    console.error('API error:', error.message);
  }
};


  const handlePaginationChange = (event, value) => {
    setPagination(prev => ({
      ...prev,
      [activeTab]: value,
    }));
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPagination(prev => ({
      ...prev,
      [activeTab]: 1, 
    }));
  };

  const handleRemittance = async (waybill, action) => {
    try {
      const urlPost = Base_Url + ndr;
      const response = await fetch(urlPost, {
        method: 'POST',
        headers: {
          token: token,
          cus_id: cusid || '',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          waybill: waybill,
          action: action
        }),
      });  
      const result = await response.json();
      if(result.status === "SUCCESS")
      {
      toast.success(result.data.message);
      }
      else 
      { 
        toast.error(result.message);
      }
  
    } catch (error) {
      toast.error('Error sending remittance action: ' + error.message);
    }
  };  

  //   const commonCells = [
  //     <td key="orderId" className="p-2">{item.user_id}</td>,
  //     <td key="awbNo" className="p-2">{item.waybill}</td>,
  //     // <td key="customerInfo" className="p-2">{item.cus_info?.[0]?.name}</td>,
  //     <td key="customerInfo" className="p-2">{item.cus_info?.name}</td>, 
  //     <td key="value" className="p-2">{item.value}</td>,
  //     <td key="lastUpdated" className="p-2">{item.timestamp}</td>,
  //   ];
  const renderTableRow = (item, index, tab) => {
    const commonCells = [
      <td key="orderId" className="p-2">{item.user_id}</td>,
      <td key="awbNo" className="p-2">{item.waybill}</td>,
      <td key="customerInfo" className="p-2">
     {tab === "Action Pending" || tab === "Action Taken" 
        ? item.cus_info?.name 
        : tab === "All Details" && item.cus_info?.name}
    </td>,
      <td key="value" className="p-2">{item.value}</td>,
      <td key="lastUpdated" className="p-2">{item.timestamp}</td>,
    ];
  
    if (activeTab === 'Action Pending') {
      commonCells.push(
        <td key="action" className="p-2">
          <div className="flex space-x-2 capitalize">
            <Button
              className="capitalize"
              sx={{ textTransform: 'capitalize', fontSize: '12px', padding: '4px 8px', minHeight: '24px' }}
              variant="contained"
              size="small"
              color="primary"
              onClick={() => handleRemittance(item.waybill, 'Reattempt')}
            >
              Reattempt
            </Button>
            <Button
              sx={{ textTransform: 'capitalize', fontSize: '12px', padding: '4px 8px', minHeight: '24px' }}
              variant="contained"
              size="small"
              color="warning"
              onClick={() => handleRemittance(item.waybill, 'Return')}
            >
              Return
            </Button>
          </div>
        </td>
      );
    } else if (activeTab === 'All Details' || 'Action Taken') {
      commonCells.push(
        <td key="action" className="p-2">
          <span>{item.action}</span>
        </td>
      );
    }
    
  
    return (
      <tr key={index} className="border-b">
        {commonCells}
      </tr>
    );
  };
  

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setPagination(prev => ({
      ...prev,
      [tab]: 1, 
    }));
  };
  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
      <div className="z-10">
        <NavBar />
      </div>
      <div className="flex flex-1 overflow-hidden">
        {isToggleSidebar && (
          <div
            className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
            style={
              window.innerWidth < 768
                ? { top: '113px', height: 'calc(100vh - 115px)', overflowY: 'auto' }
                : {}
            }
          >
            <Sidebar />
          </div>
        )}
        <div className="w-full mt-4 mb-4 mr-4 ml-4 overflow-x-hidden bg-white overflow-y-auto dark:bg-[rgba(1,14,78,0.96)] custom-scrollbar dark:shadow-custom-dark shadow-custom-light rounded-custom">
          <div className="container mx-auto p-4">
            <button className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-600 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px] w-full sm:w-full flex items-center justify-center">
              <MdDashboard className="mr-2" size={24} />
              NDR Dashboard
            </button>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4 w-full bg-cus">
              {tabs.map((tab) => (
                <button
                  key={tab}
                  className={`px-4 py-2 md:mr-12 font-sans rounded ${activeTab === tab ? ' text-white dark:bg-customPurple bg-customPurple hover:bg-purple-700 dark:hover:bg-purple-700 dark:text-white' : ' dark:bg-[rgba(1,14,78,0.96)] dark:text-white dark:shadow-custom-dark shadow-custom-light rounded-custom'
                    }`}
                  onClick={() => handleTabClick(tab)}
                >
                  {tab}
                </button>
              ))}
            </div>

            <div className="rounded-custom shadow-custom-light dark:shadow-none p-4">
              <div className="overflow-x-auto">
                <table className="min-w-full w-full table-auto dark:text-white font-sans dark:bg-[rgba(1,14,78,0.96)]">
                  <thead className="bg-[#0540a6dd] text-white text-[14px]">
                    <tr>
                      {renderTableHeaders().map((header, index) => (
                        <th key={index} className="p-2 text-left w-1/6">{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {ndrListData.length > 0 ? (
                      ndrListData.map((item, index) => renderTableRow(item, index,activeTab))
                    ) : (
                    ""
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="mt-4 flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
                <span className="text-sm text-gray-700 md:ml-8 font-bold dark:text-gray-300">{`Total Orders: ${totalOrders}`}</span>
                <select
                  id="limit"
                  value={limit}
                  onChange={handleLimitChange}
                  className="border rounded p-1 text-sm bg-white dark:bg-[rgba(1,14,78,0.96)] dark:text-gray-200"
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={totalOrders}>All</option>
                </select>
                <Pagination
                  count={Math.ceil(totalOrders / limit)}
                  page={pagination[activeTab]}
                  onChange={handlePaginationChange}
                  variant="outlined"       
                  shape="rounded"
                  className="pagination sm:mr-4 "
                  size="small"   
                  renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      className={`mx-1 dark: dark:bg-[rgba(1,14,78,0.96)] dark:text-white rounded-lg dark:shadow-custom-dark ${item.selected ? 'bg-customPurple text-white' : 'text-gray-800'}`}
                    />
                  )}
                />
              </div>
        </div>
      </div>
    </div>
  );
}
