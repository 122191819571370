import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { IoIosCloseCircle } from "react-icons/io";
import { zodResolver } from '@hookform/resolvers/zod';
import { useMyContext } from '../../context/MyContext';
import { FaWarehouse } from "react-icons/fa6";
import '../../css/custom-scrollbar.css';

const schema = z.object({
  orderId: z.string()
    .min(1, "Order ID is required")
    .regex(/^\d+$/, "Order ID must be numeric"),
  Name: z.string()
    .min(1, "Name is required")
    .regex(/^[a-zA-Z\s]+$/, "Name must only contain alphabetic characters"),
  email: z.string()
    .email("Invalid email address"),
  phone: z.string()
    .length(10, "Phone number must be exactly 10 digits")
    .regex(/^\d+$/, "Phone number must be numeric"),
  // status: z.string().min(1, "Status is required"),
  date: z.string().min(1, "Add Date is required"),
  address: z.string().min(1, "Address is required"),
  pincode: z.string()
    .length(6, "Pincode must be exactly 6 characters")
    .regex(/^\d+$/, "Pincode must be numeric"),
  city: z.string()
    .min(1, "City is required")
    .regex(/^[A-Za-z\s]+$/, "City must only contain letters and spaces"),
  state: z.string()
    .min(1, "State is required")
    .regex(/^[A-Za-z\s]+$/, "State must only contain letters and spaces"),
  country: z.string()
    .min(1, "Country is required")
    .regex(/^[A-Za-z\s]+$/, "Country must only contain letters and spaces"),
  registerName: z.string()
    .min(1, "Register Name is required")
    .regex(/^[a-zA-Z\s]+$/, "Register Name must only contain alphabetic characters"),
  returnAddress: z.string().optional(),
  returnPhone: z.string().optional(),
  returnPincode: z.string().optional(),
  returnCity: z.string().optional(),
  returnState: z.string().optional(),
  returnCountry: z.string().optional(),
});

const ViewWarehouse = ({ isopen, onClose, warehouse }) => {
  const { isDarkMode } = useMyContext();
  const [isEditMode, setIsEditMode] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (warehouse) {
      reset({
        Id: warehouse.customer_id || '',
        Name: warehouse.name || '',
        email: warehouse.email || '',
        phone: warehouse.phone || '',
        // status: warehouse.status || '',
        date: warehouse.create_date || '',
        address: warehouse.address || '',
        pincode: warehouse.pincode || '',
        city: warehouse.city || '',
        state: warehouse.state || '',
        country: warehouse.country || '',
        registerName: warehouse.name || '',
        returnAddress: warehouse.return_address || '',
        returnPhone: warehouse.return_phone || '',
        returnPincode: warehouse.return_pincode || '',
        returnCity: warehouse.return_city || '',
        returnState: warehouse.return_state || '',
        returnCountry: warehouse.return_country || '',
      });
    }
  }, [warehouse, reset]);

  const onSubmit = (data) => {
    onClose();
  };

  const handleClose = () => {
    setIsEditMode(false);
    onClose();
  };


  return (
    <Dialog open={isopen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle className="bg-customPurple text-white relative  shadow-custom-light dark:shadow-custom-dark ">
        <h2
          className="text-xl sm:text-2xl font-bold text-white font-sans px-2 py-2 rounded-custom h-[40px] w-full sm:w-full flex items-center justify-center">
          <FaWarehouse className="mr-2" size={24} />
          WareHouse Details
        </h2>
        <IoIosCloseCircle
          className="absolute  top-0 right-0 cursor-pointer"
          size={32}
          onClick={handleClose}
        />  
      </DialogTitle>

      <DialogContent className="bg-white custom-scrollbar dark:bg-[rgba(1,14,78,0.96)]" style={{ maxHeight: '70vh' }}>
        <div className="p-4 m-4 bg-custom-background shadow-custom-light dark:bg-[rgba(1,14,78,0.96)] dark:shadow-custom-dark dark:text-white rounded-custom-custom">

          <form className="space-y-6 font-sans" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">ID</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('Id')}
                  disabled={!isEditMode}
                />
                {errors.Id && <p className="text-red-500 text-sm">{errors.Id.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Name</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('Name')}
                  disabled={!isEditMode}
                />
                {errors.Name && <p className="text-red-500 text-sm">{errors.Name.message}</p>}
              </div>
            </div>

            {/* Additional form fields following the same structure */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Email</label>
                <input
                  type="email"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('email')}
                  disabled={!isEditMode}
                />
                {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Phone</label>
                <input
                  type="tel"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('phone')}
                  disabled={!isEditMode}
                />
                {errors.phone && <p className="text-red-500 text-sm">{errors.phone.message}</p>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Status</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('status')}
                  disabled={!isEditMode}
                />
                {errors.status && <p className="text-red-500 text-sm">{errors.status.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Add Date</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('date')}
                  disabled={!isEditMode}
                />
                {errors.date && <p className="text-red-500 text-sm">{errors.date.message}</p>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Address</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('address')}
                  disabled={!isEditMode}
                />
                {errors.address && <p className="text-red-500 text-sm">{errors.address.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Pincode</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('pincode')}
                  disabled={!isEditMode}
                />
                {errors.pincode && <p className="text-red-500 text-sm">{errors.pincode.message}</p>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">City</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('city')}
                  disabled={!isEditMode}
                />
                {errors.city && <p className="text-red-500 text-sm">{errors.city.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">State</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('state')}
                  disabled={!isEditMode}
                />
                {errors.state && <p className="text-red-500 text-sm">{errors.state.message}</p>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Country</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('country')}
                  disabled={!isEditMode}
                />
                {errors.country && <p className="text-red-500 text-sm">{errors.country.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Register Name</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('registerName')}
                  disabled={!isEditMode}
                />
                {errors.registerName && <p className="text-red-500 text-sm">{errors.registerName.message}</p>}
              </div>
            </div>

            {/* Optional Return Address fields */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Return Address</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('returnAddress')}
                  disabled={!isEditMode}
                />
                {errors.returnAddress && <p className="text-red-500 text-sm">{errors.returnAddress.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Return Phone</label>
                <input
                  type="tel"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('returnPhone')}
                  disabled={!isEditMode}
                />
                {errors.returnPhone && <p className="text-red-500 text-sm">{errors.returnPhone.message}</p>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Return Pincode</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('returnPincode')}
                  disabled={!isEditMode}
                />
                {errors.returnPincode && <p className="text-red-500 text-sm">{errors.returnPincode.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Return City</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('returnCity')}
                  disabled={!isEditMode}
                />
                {errors.returnCity && <p className="text-red-500 text-sm">{errors.returnCity.message}</p>}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Return State</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('returnState')}
                  disabled={!isEditMode}
                />
                {errors.returnState && <p className="text-red-500 text-sm">{errors.returnState.message}</p>}
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Return Country</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('returnCountry')}
                  disabled={!isEditMode}
                />
                {errors.returnCountry && <p className="text-red-500 text-sm">{errors.returnCountry.message}</p>}
              </div>
            </div>

            <button
              onClick={() => setIsEditMode(!isEditMode)}
              className="bg-customPurple text-white py-2 px-4 rounded-custom-custom mb-4 hover:bg-customPurpleHover focus:outline-none focus:ring-2 focus:ring-customPurple focus:ring-opacity-50"
            >
              {isEditMode ? 'Cancel' : 'Edit'}
            </button>


            {isEditMode && (
              <button
                type="submit"
                className="bg-customPurple text-white py-2 px-4 md:ml-8 rounded-custom-custom mt-4 hover:bg-customPurpleHover focus:outline-none focus:ring-2 focus:ring-customPurple focus:ring-opacity-50"
              >
                Save
              </button>
            )}
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ViewWarehouse;
