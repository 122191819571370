// import React from 'react';
// import { useMyContext } from '../../context/MyContext';
// import Sidebar from '../../component/sidebar/sidebar';
// import NavBar from '../../component/navBar/navBar';
// import { FaEnvelope, FaWhatsapp, FaHeadset, FaClock, FaArrowRight } from 'react-icons/fa';
// import '../../css/custom-scrollbar.css';

// const Support = () => {
//   const { isDarkMode, isToggleSidebar } = useMyContext();

//   const supportChannels = [
//     {
//       icon: <FaEnvelope className="w-6 h-6" />,
//       title: 'Email Support',
//       description: 'Send us your queries via email. We typically respond within 24 hours.',
//       contact: 'support@pickupxpress.com',
//       link: 'mailto:support@pickupxpress.com',
//       color: 'bg-gradient-to-r from-customPurple to-purple-700',
//     },
//     {
//       icon: <FaWhatsapp className="w-6 h-6" />,
//       title: 'WhatsApp Support',
//       description: 'Get instant support through WhatsApp messaging.',
//       contact: ' +91XXXXXX4828',
//       link: 'https://wa.me/message/LCC4KAGD5JFUC1',
//       color: 'bg-gradient-to-r from-green-500 to-green-600',
//     },
//   ];

//   return (
//     <div
//       className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'
//         }`}
//     >
//       {/* NavBar */}
//       <div className="z-10">
//         <NavBar />
//       </div>

//       {/* Main Layout */}
//       <div className="flex flex-1 overflow-hidden">
//         {isToggleSidebar && (
//           <div
//             className={`fixed z-20 h-full w-64 md:relative transition-all duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
//           >
//             <Sidebar />
//           </div>
//         )}
//         <div className="flex-1 ml-4 bg-white mt-4 dark:bg-[rgba(1,14,78,0.96)] mr-4 p-4  mb-4 md:p-8 custom-scrollbar rounded-custom shadow-custom-light dark:shadow-custom-dark">
//           <div className="max-w-5xl mx-auto rounded-custom shadow-custom-light dark:shadow-custom-dark">
//             {/* Header Section */}
//             <div className="text-center mb-12 space-y-6">
//               <div className="relative inline-block">
//                 <div className="absolute inset-0 bg-blue-500/20 dark:bg-blue-500/10 blur-3xl rounded-full"></div>
//                 <div className="relative bg-white mt-2 dark:bg-[rgba(1,14,78,0.96)] p-6 rounded-full shadow-xl">
//                   <FaHeadset className="w-16 h-16 text-customPurple animate-pulse" />
//                 </div>
//               </div>
//               <h1 className="text-4xl  font-bold mt-6 bg-clip-text text-transparent bg-gradient-to-r from-customPurple to-purple-700">
//                 How Can We Help You?
//               </h1>
//               <p className="text-lg text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
//                 Our dedicated support team is ready to assist you 24/7. Choose your preferred communication channel below.
//               </p>
//             </div>

//             {/* Support Cards */}
//             <div className="grid md:grid-cols-2 ">
//               {supportChannels.map((channel, index) => (
//                 <div
//                   key={index}
//                   className={`groupr elative ml-13 bg-white dark:bg-[rgba(1,14,78,0.96)] rounded-custom shadow-custom-light dark:shadow-custom-dark  hover:shadow-xl transform hover:-translate-y-1 transition-all duration-300 
//             ${index === 0 ? 'sm:ml-20 ml-2 mr-2' : index === 1 ? ' ml-2 mr-2 mt-6' : ''}`}
//                   style={{ maxWidth: '400px', height: '250px' }}
//                 >
//                   <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
//                   <div className="relative p-4">
//                     <div className="flex items-center mb-4">
//                       <div
//                         className={`${channel.color} p-3 rounded-lg transform group-hover:scale-105 transition-transform duration-300`}
//                       >
//                         {channel.icon}
//                       </div>
//                       <h2 className="text-xl font-bold ml-3">{channel.title}</h2>
//                     </div>
//                     <div className="space-y-2 mb-6">
//                       <p className="text-gray-600 dark:text-gray-300 text-sm">{channel.description}</p>
//                       <div className="flex items-center space-x-2">
//                         <p className="font-medium text-sm">{channel.contact}</p>
//                       </div>
//                     </div>
//                     <a
//                       href={channel.link}
//                       target="_blank"
//                       rel="noopener noreferrer"
//                       className={`flex items-center justify-between ${channel.color} text-white px-4 py-3 rounded-lg font-medium 
//                group-hover:shadow-md transition-all duration-300 hover:opacity-90 text-sm`}
//                     >
//                       <span>Contact via {channel.title.split(' ')[0]}</span>
//                       <FaArrowRight className="w-3 h-3 transform group-hover:translate-x-1 transition-transform duration-300" />
//                     </a>
//                   </div>
//                 </div>
//               ))}
//             </div>

//             {/* Business Hours Card */}
//             <div className="mt-12">
//               <div className="bg-white dark:bg-[rgba(1,14,78,0.96)] rounded-2xl shadow-lg p-6">
//                 <div className="flex items-center justify-center space-x-4 mb-4">
//                   <FaClock className="w-6 h-6 text-customPurple" />
//                   <h3 className="text-xl font-semibold">Business Hours</h3>
//                 </div>
//                 <div className="text-center space-y-2 text-gray-600 dark:text-gray-300">
//                   <p className="font-medium">Monday to Saturday : 9:00 AM - 6:00 PM</p>
//                   <p className="text-sm">
//                     For urgent matters outside business hours, please use our WhatsApp support channel for immediate
//                     assistance.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Support;



import React from 'react';
import { useMyContext } from '../../context/MyContext';
import Sidebar from '../../component/sidebar/sidebar';
import NavBar from '../../component/navBar/navBar';
import { 
  FaEnvelope, 
  FaWhatsapp, 
  FaHeadset, 
  FaClock, 
  FaArrowRight,
  FaComments,
  FaPhoneAlt
} from 'react-icons/fa';
import '../../css/custom-scrollbar.css';

const Support = () => {
  const { isDarkMode, isToggleSidebar } = useMyContext();

  const supportChannels = [
    {
      icon: <FaEnvelope className="w-8 h-8 text-white" />,
      title: 'Email Support',
      description: 'Comprehensive support via email. Detailed responses within 24 hours.',
      contact: 'support@pickupxpress.com',
      link: 'mailto:support@pickupxpress.com',
      color: 'bg-gradient-to-br from-indigo-600 to-purple-700',
      hoverColor: 'hover:from-indigo-700 hover:to-purple-800',
    },
    {
      icon: <FaWhatsapp className="w-8 h-8 text-white" />,
      title: 'WhatsApp Support',
      description: 'Instant messaging support for quick resolutions.',
      contact: '+91XXXXXX4828',
      link: 'https://wa.me/message/LCC4KAGD5JFUC1',
      color: 'bg-gradient-to-br from-green-500 to-emerald-600',
      hoverColor: 'hover:from-green-600 hover:to-emerald-700',
    }
  ];
  return (
    <div
      className={`min-h-screen flex flex-col overflow-hidden ${
        isDarkMode 
          ? 'dark:bg-gradient-to-br dark:from-[rgba(1,14,78,0.96)] dark:to-[rgba(7,28,110,0.96)]' 
          : 'bg-gray-50'
      }`}
    >
     <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
        <div className="z-10">
          <NavBar />
        </div>
        <div className="flex flex-1 overflow-hidden">
          {isToggleSidebar && (
            <div
              className={`fixed z-20 h-full w-64 md:relative duration-300 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
              style={window.innerWidth < 768 ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' } : {}}>
              <Sidebar />
            </div>
          )}
        <div className="flex-1 p-6 md:p-8 lg:p-12 overflow-y-auto custom-scrollbar">
          <div className="max-w-6xl mx-auto">
            {/* Header Section */}
            <div className="text-center mb-12 space-y-6">
              <div className="relative inline-block">
                <div className="absolute -inset-2 bg-indigo-400/20 dark:bg-indigo-400/10 blur-xl rounded-full animate-pulse"></div>
                <div className="relative bg-white dark:bg-[rgba(1,14,78,0.96)] p-6 rounded-full shadow-2xl">
                  <FaHeadset className="w-20 h-20 text-indigo-600 dark:text-indigo-400 transform hover:scale-110 transition-transform" />
                </div>
              </div>
              <h1 className="text-5xl font-extrabold mt-6 
                bg-clip-text text-transparent 
                bg-gradient-to-r from-indigo-600 to-purple-600 
                dark:from-indigo-400 dark:to-purple-500">
                Customer Support
              </h1>
              <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto leading-relaxed">
                Our dedicated support team is committed to providing exceptional service. 
                We're here to help you with any questions or concerns you may have.
              </p>
            </div>

            {/* Primary Support Channels */}
            <div className="grid md:grid-cols-2 gap-8 mb-12">
              {supportChannels.map((channel, index) => (
                <div 
                  key={index}
                  className={`
                    group relative overflow-hidden
                    bg-white dark:bg-[rgba(15,25,100,0.8)]
                    rounded-3xl shadow-2xl 
                    transform transition-all duration-300 
                    hover:-translate-y-2 hover:shadow-3xl
                    border border-gray-100 dark:border-gray-800
                  `}
                >
                  <div className="absolute inset-0 opacity-0 group-hover:opacity-10 
                    bg-gradient-to-r from-indigo-200 to-purple-200 dark:from-indigo-900 dark:to-purple-900 
                    transition-opacity duration-300"></div>
                  
                  <div className="relative p-8 space-y-6">
                    <div className={`${channel.color} p-4 rounded-2xl inline-block shadow-lg`}>
                      {channel.icon}
                    </div>
                    <h2 className="text-2xl font-bold text-gray-800 dark:text-white">
                      {channel.title}
                    </h2>
                    <p className="text-gray-600 dark:text-gray-300 text-base">
                      {channel.description}
                    </p>
                    <div className="flex items-center space-x-3">
                      <span className="font-semibold text-gray-700 dark:text-gray-200">
                        {channel.contact}
                      </span>
                    </div>
                    <a
                      href={channel.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`
                        inline-flex items-center justify-between
                        ${channel.color} ${channel.hoverColor}
                        text-white px-6 py-3 rounded-xl
                        font-semibold text-base
                        transition-all duration-300
                        hover:shadow-xl
                        group/link
                      `}
                    >
                      <span>Contact Support</span>
                      <FaArrowRight className="ml-3 w-4 h-4 
                        transform group-hover/link:translate-x-1 
                        transition-transform duration-300" 
                      />
                    </a>
                  </div>
                </div>
              ))}
            </div>
            {/* Business Hours Card */}
            <div className="mt-12">
              <div 
                className="
                  bg-white dark:bg-[rgba(15,25,100,0.8)] 
                  rounded-3xl shadow-2xl 
                  border border-gray-100 dark:border-gray-800
                  overflow-hidden
                "
              >
                <div className="p-8 text-center">
                  <div className="flex items-center justify-center space-x-4 mb-6">
                    <FaClock className="w-8 h-8 text-indigo-600 dark:text-indigo-400" />
                    <h3 className="text-2xl font-bold text-gray-800 dark:text-white">
                      Business Hours
                    </h3>
                  </div>
                  <div className="space-y-4 text-gray-600 dark:text-gray-300">
                    <p className="text-xl font-semibold">
                      Monday to Saturday : 9:00 AM - 6:00 PM
                    </p>
                    <p className="text-base max-w-2xl mx-auto">
                      For urgent matters outside business hours, our WhatsApp support channel 
                      provides immediate assistance to ensure you're never left without help.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Support;