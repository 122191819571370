import React, { useState,useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Upload, CheckCircle, AlertCircle, FileText } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useMyContext } from '../../context/MyContext';
import '../../css/custom-scrollbar.css';
import { Base_Url, uplodeDocument,userdata} from '../../config/config';
import axios from 'axios';
import { useAuth } from '../../routes/Auth';
import toast from 'react-hot-toast';


const UpdateKYC = () => {
  const { isDarkMode } = useMyContext();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const token = localStorage.getItem('token');
  const cusid = localStorage.getItem('cus_id');
  const role = localStorage.getItem('role');
  const profile_flag = localStorage.getItem('profile_flag');
  const [fieldStatuses, setFieldStatuses] = useState({
    gstin: '0',
    aadhaar: '0',
    pan: '0'
  });
  const validationSchema = z.object({
    companyName: z.string().min(1, 'Company name is required'),
    companyType: z.string().min(1, 'Company type is required'),
    gstin: z.string().min(1, 'GSTIN is required'),
    aadhaar: z.string().min(1, 'Aadhar Card is required'),
    pan: z.string().min(1, 'Pan is required'),
  });

  useEffect(() => {
    if (role === 'admin') {
      navigate('/dashboard');
    }
  }, [role, navigate]);

  useEffect(() => {
    fetchUserData();
  }, [cusid, token]);



  const fetchUserData = async () => {
    const url = Base_Url + userdata;
    if (cusid) {
      try {
        const response = await fetch(url + "/" + cusid, {
          method: 'GET',
          headers: {
            'token': token,
            'cus_id': cusid,
            'Content-Type': 'application/json', 
          },
        });
  
        const result = await response.json();
  
        if (result.status === "SUCCESS") {
          setValue('companyName', result.data.company_name);
          setValue('companyType', result.data.company_type);
          setValue('gstin', result.data.gst_number);
          setValue('aadhaar', result.data.aadhar_number);
          setValue('pan', result.data.pancard_number);
          const gstStatus =  result.data.gst_status;
          const aadharStatus =  result.data.aadhar_status;
          const panStatus =  result.data.pan_card_status;

          setFieldStatuses({
            gstin: result.data.gst_status,
            aadhaar: result.data.aadhar_status,
            pan: result.data.pan_card_status
          });

          if (result.data.user_gst_image && gstStatus !=="2" ) {
            setUploads((prev) => ({
              ...prev,
              gstin: result.data.user_gst_image,
            }));
          }
          if (result.data.user_address_image && aadharStatus !=="2" ) {
            setUploads((prev) => ({
              ...prev,
              aadhaar: result.data.user_address_image,
            }));
          }
          if (result.data.user_pancard_image && panStatus !=="2" ) {
            setUploads((prev) => ({
              ...prev,
              pan: result.data.user_pancard_image,
            }));
          }
        } else {
          toast.error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        toast.error('Something went wrong. Please try again.');
      }
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validationSchema),
  });

  const [uploads, setUploads] = useState({
    gstin: null,
    aadhaar: null,
    pan: null
  });
  const [loading, setLoading] = useState(false);
  const [fileErrors, setFileErrors] = useState({});

  const companyTypes = [
    'Private Limited',
    'Public Limited',
    'Partnership',
    'Sole Proprietorship',
    'LLP',
    'Others'
  ];

  const handleSkip = () => {
    navigate("/dashboard");
  };

  // const handleFileUpload = (field) => (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     if (file.size > 5 * 1024 * 1024) {
  //       setFileErrors((prev) => ({ ...prev, [field]: 'File size should be less than 5MB' }));
  //       return;
  //     }
  //     setUploads((prev) => ({
  //       ...prev,
  //       [field]: file
  //     }));
  //     setFileErrors((prev) => ({ ...prev, [field]: '' }));
  //   }

  //   setFieldStatuses((prev) => ({
  //     ...prev,
  //     [field]: '0'
  //   }));
  // };
 

  const handleFileUpload = (field) => (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedTypes = ['application/pdf'];
      if (!allowedTypes.includes(file.type)) {
        setFileErrors((prev) => ({ ...prev, [field]: 'Only PDF files are allowed' }));
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        setFileErrors((prev) => ({ ...prev, [field]: 'File size should be less than 5MB' }));
        return;
      }
      setUploads((prev) => ({
        ...prev,
        [field]: file
      }));
      setFileErrors((prev) => ({ ...prev, [field]: '' }));
    }

    setFieldStatuses((prev) => ({
      ...prev,
      [field]: '0'
    }));
  };



  const onSubmit = async (data) => {
    const newFileErrors = {};
    if (!uploads.aadhaar) newFileErrors.aadhaar = 'Aadhar document is required';
    if (!uploads.pan) newFileErrors.pan = 'PAN document is required';
  
    if (Object.keys(newFileErrors).length > 0) {
      setFileErrors(newFileErrors);
      return;
    }
  
    const formData = new FormData();
    formData.append('company_name', data.companyName);
    formData.append('company_type', data.companyType);
    formData.append('aadhar_number', data.aadhaar);
    formData.append('pancard_number', data.pan);
    formData.append('gst_number', data.gstin);
  
    if (uploads.gstin) formData.append('gst', uploads.gstin);
    if (uploads.aadhaar) formData.append('aadhar_card', uploads.aadhaar);
    if (uploads.pan) formData.append('pancard', uploads.pan);
  
    try {
      setLoading(true);
      const response = await axios.post(`${Base_Url}${uplodeDocument}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: token,
          cus_id: cusid
        },
      });
  
      if (response.data.status === true) {
        toast.success(response.data.message);
        navigate('/dashboard');
      } else {
        // Display each field-specific error message in a toast
        const serverErrors = response.data.message;
        if (typeof serverErrors === 'object' && serverErrors !== null) {
          Object.entries(serverErrors).forEach(([field, message]) => {
            toast.error(`${field.replace('_', ' ')}: ${message}`);
          });
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (err) {
      toast.error('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  const isFieldDisabled = (field) => {
    return fieldStatuses[field] === '1';
  };

  const getStatusMessage = (field) => {
    // Only show rejection message if status is 2 AND no new file has been uploaded
    if (fieldStatuses[field] === '2' && !uploads[field]) {
      return (
        <div className="text-red-500 text-sm mt-1">
          Your document has been rejected. Please upload a new one.
        </div>
      );
    }
    return null;
  };  
  const FileUploadInput = ({ field }) => (
    <div className="mt-2">
      {!isFieldDisabled(field) && (
        <div className={`relative border-2 border-dashed rounded-lg p-4 transition-all duration-300 ${
          uploads[field] 
            ? 'border-green-500 bg-green-50 dark:bg-green-900/20' 
            : 'border-gray-300 hover:border-purple-500 dark:border-gray-600 dark:hover:border-purple-400'
        }`}>
          <input
            type="file"
            id={`${field}-upload`}
            className="hidden"
            accept=".pdf,.jpg,.jpeg,.png"
            onChange={handleFileUpload(field)}
            disabled={isFieldDisabled(field)}
          />
          <label
            htmlFor={`${field}-upload`}
            className={`flex flex-col items-center justify-center space-y-2 ${
              isFieldDisabled(field) ? 'cursor-not-allowed' : 'cursor-pointer'
            }`}
          >
            {uploads[field] ? (
              <>
                <CheckCircle className="w-8 h-8 text-green-500" />
                <div className="flex items-center space-x-2 text-sm text-green-600 dark:text-green-400">
                  <FileText className="w-4 h-4" />
                  <span className="font-medium truncate max-w-xs">
                    {uploads[field].name}
                  </span>
                </div>
                <span className="text-xs text-green-500">File uploaded successfully</span>
              </>
            ) : (
              <>
                <Upload className="w-8 h-8 text-gray-400 dark:text-gray-300" />
                <div className="text-sm text-gray-600 dark:text-gray-300">
                  <span className="font-medium text-purple-600 dark:text-purple-400">
                    Click to upload
                  </span> or drag and drop
                </div>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  PDF, PNG, JPG or JPEG (max. 5MB)
                </p>
              </>
            )}
          </label>
        </div>
      )}
      {fileErrors[field] && (
        <div className="flex items-center space-x-1 mt-1 text-red-500 text-sm">
          <AlertCircle className="w-4 h-4" />
          <span>{fileErrors[field]}</span>
        </div>
      )}
      {getStatusMessage(field)}
      {isFieldDisabled(field) && (
        <div className="text-green-500 text-sm mt-1">
          Your document has been verified
        </div>
      )}
    </div>
  );

  return (
    <div className="h-screen flex flex-col overflow-hidden bg-gray-100">
      <div className="w-full p-8 dark:bg-[rgba(1,14,78,0.96)] font-sans flex flex-col md:flex-row gap-8 h-full items-stretch">
        <div className="w-full md:w-4/12 p-6 rounded-custom dark:bg-[rgba(1,14,78,0.96)] shadow-custom-light dark:shadow-custom-dark flex flex-col justify-between h-full">
          <button
            type="button"
            className="w-full bg-customPurple text-white p-1 rounded-custom hover:bg-purple-700 transition-all duration-300 transform hover:scale-[0.99] active:scale-95"
          >
            Update Your KYC
          </button>
          <button
            type="button"
            onClick={handleSkip}
            className="w-full bg-red-500 text-white p-1 mt-3 rounded-custom hover:bg-red-700 transition-all duration-300 transform hover:scale-[0.99] active:scale-95"
          >
            Skip
          </button>
          
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 dark:bg-[rgba(1,14,78,0.96)] overflow-y-auto custom-scrollbar h-full mt-2">
            <div>
              <label className="block text-sm font-medium dark:text-white">Company Name</label>
              <input
                type="text"
                {...register('companyName')}      
               className="mt-1 block w-full border border-gray-300 rounded-custom dark:bg-[rgba(1,14,78,0.96)] dark:text-white p-2 focus:border-customPurple"
              />
              {errors.companyName && <p className="text-red-500 text-sm mt-1">{errors.companyName.message}</p>}
            </div>

            <div>
              <label className="block text-sm font-medium dark:text-white">Company Type</label>
              <select
                {...register('companyType')}
                className="mt-1 block w-full border border-gray-300 rounded-custom dark:bg-[rgba(1,14,78,0.96)] dark:text-white p-2 focus:border-customPurple"
              >
                <option value="">Select Company Type</option>
                {companyTypes.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
              {errors.companyType && <p className="text-red-500 text-sm mt-1">{errors.companyType.message}</p>}
            </div>

            {['gstin', 'aadhaar', 'pan'].map((field) => (
              <div key={field} className="space-y-2">
                <label className="block text-sm font-medium dark:text-white capitalize">
                  {field === 'gstin' ? 'GSTIN' : `${field} Number`}
                </label>
                <input
                  type="text"
                  {...register(field)}
                  disabled={isFieldDisabled(field)}
                  className="block w-full border border-gray-300 rounded-custom dark:bg-[rgba(1,14,78,0.96)] dark:text-white p-2 focus:border-customPurple"
                  placeholder={`Enter ${field.toUpperCase()}`}
                />
                {errors[field] && <p className="text-red-500 text-sm">{errors[field].message}</p>}
                <FileUploadInput field={field} />
              </div>
            ))}

            <button
              type="submit"
              disabled={loading}
              className={`w-full bg-customPurple text-white p-2 rounded-custom hover:bg-purple-700 transition-all duration-300 transform hover:scale-[0.99] active:scale-95 mt-6 ${loading ? 'opacity-70 cursor-not-allowed' : ''}`}
            >
              {loading ? (
                <span className="flex items-center justify-center gap-2">
                  <span className="animate-spin h-4 w-4 border-2 border-white border-t-transparent rounded-full"></span>
                  Submitting...
                </span>
              ) : (
                'Submit'
              )}
            </button>
          </form>
        </div>
        <div className="w-full md:w-9/12 p-6 rounded-custom flex flex-col h-full shadow-custom-light dark:shadow-custom-dark">
          <img
            src={isDarkMode ? "/assets/images/Less_dark.png" : "/assets/images/Less.jpg"}
            alt="Image Description"
            className="rounded-custom shadow-custom-light dark:shadow-custom-dark h-full object-cover transition-transform duration-500 hover:scale-[1.02]"
          />
        </div>
      </div>
    </div>
  );
};

export default UpdateKYC;