
import React, { useEffect, useState } from 'react';
import { Search } from 'lucide-react';
import { CheckCircle, Trash, CheckIcon, XCircle, FileText, User, Building, CreditCard, ArrowRight } from 'lucide-react';
import { toast } from 'react-hot-toast';
// import {XCircleIcon } from "@heroicons/react/solid";
import axios from 'axios';
import Sidebar from '../../component/sidebar/sidebar';
import NavBar from '../../component/navBar/navBar';
import { useMyContext } from '../../context/MyContext';
import { Base_Url, userlist, userdocApprove, Url_For_View_Document } from '../../config/config';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useAuth } from '../../routes/Auth';
import { FaEye } from 'react-icons/fa'
import ViewAllCustomerModel from '../../../src/component/models/ViewAllCustomerModel';

const KYCPending = () => {
  const { isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(false);
  const { isToggleSidebar, isDarkMode } = useMyContext();
  const [customers, setCustomers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [selectedApproval, setSelectedApproval] = useState({});
  const token = localStorage.getItem('token');
  const cus_id = localStorage.getItem('cus_id');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCustomers, setTotalCustomers] = useState();
  const [limit, setLimit] = useState(10);
  const role = localStorage.getItem('role');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  useEffect(() => {
    getDocumentList();
  }, [currentPage, limit]);


  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredCustomers(customers);
      // setTotalCustomers(customers.length);
    } else {
      const filtered = customers.filter(customer =>
        customer.aadhar_number?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredCustomers(filtered);
      // setTotalCustomers(filtered.length);
    }
  }, [searchQuery, customers]);

  const getDocumentList = async () => {
    const url = `${Base_Url}admin/${userlist}?page=${currentPage}&limit=${limit}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          token: token,
          cus_id: cus_id,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      if (data.status === "SUCCESS") {
        const filteredCustomers = data.data.filter(customer => customer.role !== 'admin');
        const adminCount = data.data.filter(customer => customer.role === 'admin').length;
        const adjustedTotal = data.pagination.totalItems - adminCount;
        setCustomers(filteredCustomers);
        setTotalCustomers(adjustedTotal);
      } else {
        toast.error(`Error: ${data.message} (Code: ${data.code})`);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const handlePaginationChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setCurrentPage(1);
  };

  const handleRadioChange = (customerId, documentType, status) => {
    setSelectedApproval((prev) => ({
      ...prev,
      [customerId]: {
        ...prev[customerId],
        [documentType]: status
      }
    }));
  };

  const hasAnyRadioSelection = (customer) => {
    const customerSelections = selectedApproval[customer.id] || {};

    // Check if there are any pending documents that need approval
    const hasPendingAadhar = customer.user_address_image && customer.aadhar_status === "0";
    const hasPendingPan = customer.user_pancard_image && customer.pan_card_status === "0";
    const hasPendingGst = customer.user_gst_image && customer.gst_status === "0";

    // Check if any of the pending documents have a radio selection
    const hasAadharSelection = hasPendingAadhar && customerSelections.address;
    const hasPanSelection = hasPendingPan && customerSelections.pancard;
    const hasGstSelection = hasPendingGst && customerSelections.gstin;

    return hasAadharSelection || hasPanSelection || hasGstSelection;
  };


  const shouldShowSubmitButton = (customer) => {
    // Don't show if all documents are already approved
    const allApproved =
      customer.aadhar_status === "1" &&
      customer.pan_card_status === "1" &&
      customer.gst_status === "1";

    // Don't show if all documents are already rejected
    const allRejected =
      customer.aadhar_status === "2" &&
      customer.pan_card_status === "2" &&
      customer.gst_status === "2";

    // Don't show if no documents are uploaded
    const noDocuments =
      !customer.user_address_image &&
      !customer.user_pancard_image &&
      !customer.user_gst_image;

    // Show only if there are pending documents and at least one radio selection
    return !allApproved && !allRejected && !noDocuments && hasAnyRadioSelection(customer);
  };



  const handleSubmit = async (customerId) => {
    // Get customer details
    const customer = customers.find(c => c.id === customerId);

    // Check if any documents exist and their current statuses
    const hasAadharDoc = customer?.user_address_image;
    const hasPanDoc = customer?.user_pancard_image;
    const hasGstDoc = customer?.user_gst_image;
    const aadharStatus = customer?.aadhar_status; // Assuming "1" means already approved
    const panStatus = customer?.pan_card_status;
    const gstStatus = customer?.gst_status;

    // Check selected statuses from the UI
    const selectedAadhar = selectedApproval[customerId]?.address;
    const selectedPan = selectedApproval[customerId]?.pancard;
    const selectedGst = selectedApproval[customerId]?.gstin;

    // Collect missing selections only for documents that are not already approved
    const missingSelections = [];

    if (hasAadharDoc && aadharStatus !== "1" && !selectedAadhar) {
      missingSelections.push("Aadhaar");
    }
    if (hasPanDoc && panStatus !== "1" && !selectedPan) {
      missingSelections.push("PAN Card");
    }
    if (hasGstDoc && gstStatus !== "1" && !selectedGst) {
      missingSelections.push("GSTIN");
    }

    // Show error if there are missing selections
    if (missingSelections.length > 0) {
      toast.error(
        `Please select approval or reject status for: ${missingSelections.join(", ")}`
      );
      return;
    }

    try {
      setLoading((prev) => ({ ...prev, [customerId]: true }));
      const approvalData = {
        customer_id: customerId,
        aadhar_status: aadharStatus === "1" ? "1" : selectedApproval[customerId]?.address,
        pancard_status: panStatus === "1" ? "1" : selectedApproval[customerId]?.pancard,
        gst_status: gstStatus === "1" ? "1" : selectedApproval[customerId]?.gstin,
      };

      const response = await axios.post(
        `${Base_Url}admin/${userdocApprove}`,
        approvalData,
        {
          headers: {
            token: token,
            cus_id: cus_id,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.status === true) {
        toast.success("Documents Updated Successfully!");
        getDocumentList();
      } else {
        toast.error(`Error: ${response.data.message}`);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      setLoading((prev) => ({ ...prev, [customerId]: false }));
    }
  };


  const handleDocumentClick = (url) => {
    if (!isAuthenticated) {
      window.location.href = '/dashboard';
    } else {
      window.open(url, '_blank');
    }
  };
  // const handleSearchChange = (event) => {
  //   setSearchQuery(event.target.value);

  //   setCurrentPage(1); 
  // };

  const handleSearchChange = (event) => {
    const value = event.target.value;

    // Allow only numeric input up to 12 digits
    if (!/^\d{0,12}$/.test(value)) {
      return;
    }

    setSearchQuery(value);
    setCurrentPage(1);

    if (value.length === 12) {
      const filtered = customers.filter((customer) =>
        customer.aadhar_number?.toLowerCase().includes(value.toLowerCase())
      );
      if (filtered.length === 0) {
        toast.error("No customer found with this Aadhaar number.");
      }
      setFilteredCustomers(filtered);
      setTotalCustomers(filtered.length);
    } else if (value === "") {
      setFilteredCustomers([]);
      // setTotalCustomers(0);
    }
  };

  const handleClick = (customer) => {
    setSelectedCustomer(customer);
    setIsPopupOpen(true);
  };

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
      <div className="z-10">
        <NavBar />
      </div>
      <div className="flex flex-1 overflow-hidden">
        {isToggleSidebar && (
          <div className="fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20"
            style={window.innerWidth < 768 ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' } : {}}>
            <Sidebar />
          </div>
        )}
        <div className="min-h-screen dark:bg-[rgba(1,14,78,0.96)] p-4 w-full overflow-y-auto">
          <div className="max-w-7xl mx-auto mb-36">
            <div className="mb-6 max-w-[360px]">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search by Aadhaar number..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="w-full px-4 py-2 pr-10 rounded-lg  border border-gray-300 dark:border-gray-700 
                           bg-white dark:bg-[rgba(1,14,78,0.96)] text-gray-900 dark:text-white
                           focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <Search className="absolute right-3 top-5 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {(searchQuery.trim().length === 12 && filteredCustomers.length === 0) ? (
                <div className="col-span-full text-center top-96 text-red-500  font-bold dark:text-white">
                  No customers found with this Aadhaar number.
                </div>) : (
                (filteredCustomers.length > 0 ? filteredCustomers : customers).map((customer) => (
                  <div key={customer.id} className="bg-white dark:bg-[rgba(1,14,78,0.96)] shadow-custom-light dark:shadow-custom-dark rounded-lg overflow-hidden p-6 flex flex-col h-full">
                    <div className="border-b border-gray-200 dark:border-gray-700 pb-4 mb-4 flex justify-between items-center">
                      <h2 className="text-lg font-bold text-gray-800 dark:text-white flex items-center gap-2">
                        <User className="w-5 h-5" />
                        {customer.company_name}
                      </h2>
                      <button
                        className="text-blue-600 hover:text-blue-700 ml-2"
                        onClick={() => handleClick(customer)}
                      >
                        <FaEye className="w-5 h-5" />
                      </button>
                    </div>
                    <div className="space-y-2">
                      <h3 className="text-sm font-semibold text-gray-800 dark:text-white flex items-center gap-2">
                        <Building className="w-4 h-4" /> Company Type: {customer.company_type || 'N/A'}
                      </h3>
                    </div>
                    <div className="space-y-2 mt-4">
                      <h3 className="text-sm font-semibold text-gray-800 dark:text-white flex items-center gap-2">
                        <CreditCard className="w-4 h-4" /> ID Information
                      </h3>
                      <p className="text-sm text-gray-600 dark:text-gray-300">GSTIN: {customer.gst_number || 'N/A'}</p>
                      <p className="text-sm text-gray-600 dark:text-gray-300">Aadhaar: {customer.aadhar_number || 'N/A'}</p>
                      <p className="text-sm text-gray-600 dark:text-gray-300">PAN: {customer.pancard_number || 'N/A'}</p>
                    </div>

                    <div className="space-y-2 mt-4">
                      <h3 className="text-sm font-semibold text-gray-800 dark:text-white flex items-center gap-2">
                        <FileText className="w-4 h-4" /> Documents
                      </h3>
                      {customer.user_address_image && (
                        <div className="flex items-center justify-between">
                          <span className="text-sm text-gray-600 dark:text-gray-300">Aadhaar:</span>
                          <div className="flex items-center gap-2">
                            <button
                              className="text-sm text-blue-600 hover:text-blue-700"
                              onClick={() => handleDocumentClick(Url_For_View_Document + customer.user_address_image)}
                            >
                              View
                            </button>
                            {customer.aadhar_status === "1" ? (
                              <CheckCircle className="text-green-500 w-5 h-5" />
                            ) : customer.aadhar_status === "2" ? (
                              <XCircle className="text-red-500 w-5 h-5" />)
                              : (
                                <>
                                  <label className="flex items-center gap-2">
                                    <input
                                      type="radio"
                                      name={`approval-${customer.id}-address`}
                                      value="1"
                                      checked={selectedApproval[customer.id]?.address === "1"}
                                      onChange={() => handleRadioChange(customer.id, 'address', "1")}
                                    />
                                    Approve
                                  </label>
                                  <label className="flex items-center gap-2">
                                    <input
                                      type="radio"
                                      name={`approval-${customer.id}-address`}
                                      value="2"
                                      checked={selectedApproval[customer.id]?.address === "2"}
                                      onChange={() => handleRadioChange(customer.id, 'address', "2")}
                                    />
                                    Reject
                                  </label>
                                </>
                              )}
                          </div>
                        </div>
                      )}
                      {customer.user_pancard_image && (
                        <div className="flex items-center justify-between">
                          <span className="text-sm text-gray-600 dark:text-gray-300">PAN Card:</span>
                          <div className="flex items-center gap-2">
                            <button
                              className="text-sm text-blue-600 hover:text-blue-700"
                              onClick={() => handleDocumentClick(Url_For_View_Document + customer.user_pancard_image)}
                            >
                              View
                            </button>
                            {customer.pan_card_status === "1" ? (
                              <CheckCircle className="text-green-500 w-5 h-5" />
                            ) : customer.pan_card_status === "2" ? (
                              <XCircle className="text-red-500 w-5 h-5" />
                            ) :
                              (
                                <>
                                  <label className="flex items-center gap-2">
                                    <input
                                      type="radio"
                                      name={`approval-${customer.id}-pancard`}
                                      value="1"
                                      checked={selectedApproval[customer.id]?.pancard === "1"}
                                      onChange={() => handleRadioChange(customer.id, 'pancard', "1")}
                                    />
                                    Approve
                                  </label>
                                  <label className="flex items-center gap-2">
                                    <input
                                      type="radio"
                                      name={`approval-${customer.id}-pancard`}
                                      value="2"
                                      checked={selectedApproval[customer.id]?.pancard === "2"}
                                      onChange={() => handleRadioChange(customer.id, 'pancard', "2")}
                                    />
                                    Reject
                                  </label>
                                </>
                              )}
                          </div>
                        </div>
                      )}
                      {customer.user_gst_image && (
                        <div className="flex items-center justify-between">
                          <span className="text-sm text-gray-600 dark:text-gray-300">GSTIN:</span>
                          <div className="flex items-center gap-2">
                            <button
                              className="text-sm text-blue-600 hover:text-blue-700"
                              onClick={() => handleDocumentClick(Url_For_View_Document + customer.user_gst_image)}
                            >
                              View
                            </button>
                            {customer.gst_status === "1" ? (
                              <CheckCircle className="text-green-500 w-5 h-5" />
                            ) : customer.gst_status === "2" ? (
                              <XCircle className="text-red-500 w-5 h-5" />) :
                              (
                                <>
                                  <label className="flex items-center gap-2">
                                    <input
                                      type="radio"
                                      name={`approval-${customer.id}-gstin`}
                                      value="1"
                                      checked={selectedApproval[customer.id]?.gstin === "1"}
                                      onChange={() => handleRadioChange(customer.id, 'gstin', "1")}
                                    />
                                    Approve
                                  </label>
                                  <label className="flex items-center gap-2">
                                    <input
                                      type="radio"
                                      name={`approval-${customer.id}-gstin`}
                                      value="2"
                                      checked={selectedApproval[customer.id]?.gstin === "2"}
                                      onChange={() => handleRadioChange(customer.id, 'gstin', "2")}
                                    />
                                    Reject
                                  </label>
                                </>
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mt-4">
                      {customer.aadhar_status === "1" &&
                        customer.pan_card_status === "1" &&
                        customer.gst_status === "1" ? (
                        <p className="bg-green-500 text-white px-4 py-2 rounded-lg text-center">
                          Document Verified
                        </p>
                      ) : customer.aadhar_status === "2" &&
                        customer.pan_card_status === "2" &&
                        customer.gst_status === "2" ? (
                        <p className="bg-red-500 text-white px-4 py-2 rounded-lg text-center">
                          Document Rejected
                        </p>
                      ) : !customer.user_address_image &&
                        !customer.user_pancard_image &&
                        !customer.user_gst_image ? (
                        <p className="font-medium text-medium text-red-500 px-4 py-2 rounded-lg">
                          Upload Required Documents to Update the KYC
                        </p>
                      ) : shouldShowSubmitButton(customer) ? (
                        <button
                          className="bg-blue-600 text-white px-4 py-2 rounded-lg w-full"
                          onClick={() => handleSubmit(customer.id)}
                          disabled={loading[customer.id]}
                        >
                          {loading[customer.id] ? "Submitting..." : "Submit"}
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className="mt-4 flex flex-col sm:flex-row mb-8 justify-between items-center space-y-2 sm:space-y-0">
              {/* <span className="text-sm text-gray-700  font-bold dark:text-gray-300">{`Total Customers: ${totalCustomers}`}</span> */}
              <span className="text-sm text-gray-700 font-bold dark:text-gray-300">
                {`Total Customers: ${totalCustomers}`}
                {searchQuery && ` (Filtered: ${filteredCustomers.length})`}
              </span>
              <select
                id="limit"
                value={limit}
                onChange={handleLimitChange}
                className="border rounded p-1 text-sm bg-white dark:bg-[rgba(1,14,78,0.96)] dark:text-gray-200"
              >
                {totalCustomers === 0 ? (
                  <option value={0}>0</option>
                ) : (
                  <>
                    {totalCustomers >= 10 && <option value={10}>10</option>}
                    {totalCustomers >= 15 && <option value={15}>15</option>}
                    {totalCustomers >= 20 && <option value={20}>20</option>}
                    {totalCustomers > 30 && <option value={totalCustomers}>All</option>}
                  </>
                )}
              </select>
              <Pagination
                count={Math.ceil(totalCustomers / limit)}
                page={currentPage}
                onChange={handlePaginationChange}
                color="primary"
                variant="outlined"
                shape="rounded"
                size="small"
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    className={`paginationItem border text-blue-600 dark:text-blue-400 hover:text-white dark:hover:text-white border-gray-200 dark:border-gray-700`}
                  />
                )}
              />
            </div>
            <ViewAllCustomerModel isopen={isPopupOpen} onClose={handleClose} customer={selectedCustomer} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default KYCPending;
