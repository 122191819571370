
import React, { useEffect, useState } from 'react';
import Sidebar from '../../../component/sidebar/sidebar';
import NavBar from '../../../component/navBar/navBar';
import Pagination from '@mui/material/Pagination';
import { useMyContext } from '../../../context/MyContext';
import { FaCcVisa, FaCcMastercard } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import PaginationItem from '@mui/material/PaginationItem';
import { Base_Url, topUpList } from '../../../config/config';

const TopupHistory = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { isDarkMode, isToggleSidebar } = useMyContext();
  const navigate = useNavigate();
  const [historyData, setHistoryData] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const token = localStorage.getItem('token');
  const cus_id = localStorage.getItem('cus_id');

  useEffect(() => {
    getShipmentCharges()
  }, [page, limit])

  const handleNavigation = () => {
    navigate("/dashboard");
  };

  const getShipmentCharges = async () => {
    debugger
    // const url = `${Base_Url}${topUpList}?page=${page}&limit=${limit}`;
    const url = Base_Url + topUpList;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          token: token,
          cus_id: cus_id,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (data.status === "SUCCESS") {
        setHistoryData(data.data);
        setTotalOrders(data.pagination.totalItems || 0);
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };


  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(1);
  };

  const renderTableHeaders = () => {
    return ['Order ID', 'Amount', 'Last Balance','Transaction Status', 'Generate Date'];
  };

  // const renderTableRow = (row) => (
  //   <tr key={row.id} className="border-b dark:border-gray-600">
  //     <td className="p-2">{row.orderid}</td>
  //     <td className="p-2">{row.amount}</td>
  //     <td className="p-2">{row.lastBalance}</td>
  //     <td className="p-2">{row.transactionType}</td>
  //     <td className="p-2">{row.status}</td>
  //     <td className="p-2">{row.date}</td>
  //   </tr>
  // ); 

  const renderTableRow = (row) => (
    <tr key={row.id} className="border-b dark:border-gray-600">
      <td className="p-2">{row.order_id}</td>
      <td className="p-2">{row.credit_amount || row.debit_amount}</td>
      <td className="p-2">{row.closing_balance || 'N/A'}</td>
      <td className="p-2">{row.status}</td>
      {/* <td className="p-2">{row.status}</td> */}
      <td className="p-2">{row.txn_date || 'N/A'}</td>
    </tr>
  );


  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
      <NavBar />
      <div className="flex flex-1 overflow-hidden">
        {isToggleSidebar && (
          <div className={`fixed z-20 h-full w-64 md:relative bg-white dark:bg-[rgba(1,14,78,0.96)] dark:text-white mt-4 duration-300 ${window.innerWidth < 768 ? 'top-28' : ''}`}>
            <Sidebar />
          </div>
        )}
        <div className="dark:bg-[rgba(1,14,78,0.96)] w-full dark:text-white shadow-custom-light dark:shadow-none rounded-custom mt-4 p-4 md:ml-4 md:mt-4 md:mb-8 md:mr-4 custom-scrollbar overflow-y-auto">
          <button onClick={handleNavigation}
            className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 dark:bg-customPurple dark:hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px] w-full flex items-center justify-center">
            <FaCcVisa className="mr-2" size={24} />
            <FaCcMastercard className="mr-2" size={24} />
            Shipments Charges
          </button>
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto">
              <thead className="bg-[#0540a6dd] text-white text-[14px]">
                <tr>
                  {renderTableHeaders().map((header, index) => (
                    <th key={index} className="p-2 text-left">{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {historyData.length > 0 ? historyData.map(row => renderTableRow(row)) : (
                  <tr>
                    <td colSpan="6" className="text-center p-4">No top-up history available.</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="mt-4 flex flex-col sm:flex-row justify-between items-center space-y-2 sm:space-y-0">
              <span className="text-sm text-gray-700 font-bold dark:text-gray-300">{`Total Orders: ${totalOrders}`}</span>
              <select
                id="limit"
                value={limit}
                onChange={handleLimitChange}
                className="border rounded p-1 ml-[480px] text-sm bg-white dark:bg-[rgba(1,14,78,0.96)] dark:text-gray-200"
              >
                {totalOrders === 0 ? (
                  <option value={0}>0</option>
                ) : (
                  <>
                    {totalOrders <= 10 && <option value={10}>10</option>}
                    {totalOrders <= 15 && <option value={15}>15</option>}
                    {totalOrders <= 20 && <option value={20}>20</option>}
                    {totalOrders >= 50 && <option value={totalOrders}>All</option>}
                  </>
                )}
              </select>

              <Pagination
                count={Math.ceil(totalOrders / limit)}
                page={page}
                onChange={handlePaginationChange}
                variant="outlined"
                shape="rounded"
                className="pagination"
                size="small"
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    className={`mx-1 rounded-md ${item.selected ? 'bg-customPurple text-white' : 'text-gray-700 dark:text-gray-300'} hover:bg-purple-700 hover:text-white`}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopupHistory;
