import { useAuth } from './Auth';
import { Outlet, Navigate } from 'react-router-dom';

const Protectedroute = () => {
  const { isAuthenticated, loading } = useAuth();
  if (loading) {
    return <div>Loading...</div>; 
  }
  return isAuthenticated ? <Outlet /> : <Navigate to="/" replace />;
};

export default Protectedroute;
