import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import { useMyContext } from "../../../context/MyContext";
import Sidebar from "../../../component/sidebar/sidebar";
import NavBar from "../../../component/navBar/navBar";

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams();
   const {isToggleSidebar,isDarkMode} = useMyContext();
  useEffect(() => {
    // Extract query parameters from the URL
    const paymentId = searchParams.get("razorpay_payment_id");
    const linkId = searchParams.get("razorpay_payment_link_id");
    const status = searchParams.get("razorpay_payment_link_status");
    const signature = searchParams.get("razorpay_signature");

    // Handle the payment status
    if (status === "paid") {
      toast.success(`Payment successful! Transaction ID: ${paymentId}`);

      // Optional: Verify payment details with the backend
      axios
        .post("/api/verify-payment", { paymentId, linkId, signature })
        .then((response) => {
          toast.success("Payment verified successfully!");
        })
        .catch((error) => {
          console.error(error);
          toast.error("Verification failed!");
        });
    } else {
      toast.error("Payment was not successful.");
    }
  }, [searchParams]);

  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
    <div className="z-10">
      <NavBar />
    </div>

    <div className="flex flex-1 overflow-hidden">
      {isToggleSidebar && (
        <div
          className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
          style={
            window.innerWidth < 768 
              ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
              : {}
          }  >
          <Sidebar />
        </div>
      )}
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50">
      <h1 className="text-2xl font-bold text-green-600">Payment Successful</h1>
      <p className="text-gray-700 mt-4">
        Thank you for your payment! Your transaction was completed successfully.
      </p>
      <a
        href="/dashboard"
        className="mt-6 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
      >
        Go to Dashboard
      </a>
    </div>
    </div>
    </div>
  );
};

export default PaymentSuccess;
