import { useState } from 'react';
import { Button } from '@mui/material';
import Sidebar from '../../../component/sidebar/sidebar';
import NavBar from '../../../component/navBar/navBar';
import Pagination from '@mui/material/Pagination';
import { useMyContext } from '../../../context/MyContext';
import { FaCcVisa, FaCcMastercard } from 'react-icons/fa';
import '../../../css/custom-scrollbar.css';
import { useNavigate } from 'react-router-dom';

const PageRemittances = () => {
    const [activeTab, setActiveTab] = useState('Total Remittance');
    const { isDarkMode, isToggleSidebar } = useMyContext();

    const handleNavigation = ()=>{
        navigation("/dashboard");
    }
    

    const navigation = useNavigate();


    const tabs = [
        { id: 'Total Remittance', label: 'Total Remittance' },
        { id: 'Next Remittance', label: 'Next Remittance' },
        { id: 'Future Remittance', label: 'Future Remittance' },
        { id: 'Last Remittance', label: 'Last Remittance' },
    ];

    const remittanceData = [
        { id: "2", date: '2023-08-27', amount: '$1000.00', status: 'Completed' },
        { id: "2", date: '2023-08-27', amount: '$1000.00', status: 'Completed' },
        { id: "2", date: '2023-08-27', amount: '$1000.00', status: 'Completed' },
        { id: "2", date: '2023-08-27', amount: '$1000.00', status: 'Completed' },
        { id: "2", date: '2023-08-27', amount: '$1000.00', status: 'Completed' },
        { id: "2", date: '2023-08-27', amount: '$1000.00', status: 'Completed' },
    ];

    return (  
        <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
            <div className="z-10">
                <NavBar />
            </div>

            <div className="flex flex-1 overflow-hidden">
                {isToggleSidebar && (
                    <div
                        className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
                        style={
                            window.innerWidth < 768 
                              ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                              : {}
                          }  >
                        <Sidebar />
                    </div>
                )}
                    <div className="container mx-auto p-4 shadow-custom-light rounded-custom md:mt-4 md:mb-4 md:mr-4 md:ml-4  dark:shadow-custom-dark overflow-y-auto custom-scrollbar">
                    <button  onClick={handleNavigation} 
                    className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 dark:bg-customPurple dark:hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px] w-full sm:w-full flex items-center justify-center">
                        <FaCcVisa className="mr-2" size={24} />
                        <FaCcMastercard className="mr-2" size={24} />
                        Remittance
                    </button>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                        {tabs.map((tab) => (
                            <button
                                key={tab.id}
                                className={`px-4 py-2 rounded dark:bg-[rgba(1,14,78,0.96)] dark:text-white dark:shadow-custom-dark ${activeTab === tab.id
                                    ? 'bg-customPurple text-white dark:bg-customPurple hover:bg-purple-700 dark:hover:bg-purple-700 dark:text-white'
                                    : 'dark:bg-[rgba(1,14,78,0.96)] dark:text-white dark:shadow-custom-dark shadow-custom-light rounded-custom'
                                    }`}
                                onClick={() => setActiveTab(tab.id)}
                            >
                                {tab.label}
                            </button>
                        ))}
                    </div>

                    <div className="overflow-x-auto shadow-custom-light rounded-custom dark:shadow-none">
                        <div className={`rounded-custom p-4 ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] rounded-custom text-white" : "bg-white "}`}>
                            <h2 className="text-xl font-semibold mb-4">{activeTab}</h2>

                            {remittanceData.length > 0 ? (
                                <div className="overflow-x-auto">
                                    <table className="min-w-full table-auto">
                                        <thead className="bg-[#0540a6dd] text-white text-[14px]">
                                            <tr>
                                                <th className="p-2 text-left">Tracking Id</th>
                                                <th className="p-2 text-left">Date</th>
                                                <th className="p-2 text-left">Amount</th>
                                                <th className="p-2 text-left">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {remittanceData.map((row, index) => (
                                                <tr key={index} className="border-b">
                                                    <td className="p-2">{row.id}</td>
                                                    <td className="p-2">{row.date}</td>
                                                    <td className="p-2">{row.amount}</td>
                                                    <td className="p-2">{row.status}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <p className="text-gray-600">No data available for this tab.</p>
                            )}
                            <div className="flex justify-between items-center mt-4 pb-4 pr-4 pl-4">
                                <p>Showing <b>1</b> of <b>50</b> results</p>
                                <Pagination
                                 sx={{
                                    '.MuiPaginationItem-text': {
                                      color: isDarkMode ? 'white' : 'defaultColor', 
                                    },
                                    '.MuiPaginationItem-root': {
                                    },
                                  }}
                                count={10} color="primary" size="small" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default PageRemittances;
