import React, { useEffect, useLayoutEffect, useState } from 'react';
import NavBar from '../../component/navBar/navBar';
import Sidebar from '../../component/sidebar/sidebar'
import { useMyContext } from '../../context/MyContext';
import { IoMdEye } from 'react-icons/io';
import { CiEdit } from 'react-icons/ci';
import { Button, Pagination } from '@mui/material';
import { FaUsers, FaSync, FaCalendarAlt, FaRocket } from 'react-icons/fa';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { FcPrint } from "react-icons/fc";
import { Base_Url, dashboard, forwardorderlist, shipmentlabel ,userdata} from '../../config/config';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import ViewAllShipment from '../../component/models/ViewAllShipment';
import { useAuth } from '../../routes/Auth';

const DashBoard = () => {
  const { isToggleSidebar, isDarkMode } = useMyContext();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedShipment,setSelectedShipment] = useState();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [error, setError] = useState(null);
  const [filteredShipmemt,setFilteredShipment] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totaldata,setTotalData] = useState();
  const navigate = useNavigate();
  const { logout } = useAuth()
  const {profile_flag,setProfile} =  useMyContext()

  const token = localStorage.getItem('token');
  // const profile_flag = localStorage.getItem('profile_flag');
  const cusid = localStorage.getItem('cus_id');
  const role = localStorage.getItem('role');
  const dashboardUrl = Base_Url + dashboard;
  const url = Base_Url + forwardorderlist;

  useEffect(() => {
    fetchOrderData();
    totalData();
    fetchUserData()
  }, []);



  const fetchUserData = async () => {
    const url = Base_Url + userdata;
    if (cusid) {
      try {
        const response = await fetch(url + "/" + cusid, {
          method: 'GET',
          headers: {
            'token': token,
            'cus_id': cusid,
            'Content-Type': 'application/json',
          },
        });
        const result = await response.json();
        setProfile(result.data.profile_flag)
    
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  };


  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate('/login')
    logout()
    localStorage.removeItem('cus_id')
    localStorage.removeItem('token')
  }

  const fetchOrderData = async () => {
    try {
      const response = await fetch(url, {
        // const response = await fetch("http://192.168.1.36/backendci4/api/forwardorderlist", {
        method: 'GET',
        headers: {
          token,
          cus_id: cusid,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (response.ok) {
        setShipments(data.data || []);
        setFilteredShipment(data.data.slice(0,9));
      }
      else if (data.status === "FAIL" && data.message === "Unauthorized access - Invalid token" )
      {
        toast.error(data.message  + " Please Login Again");
        handleLogout();
      }
       else {
        setError('Error fetching data: ' + data.message);
      }
    } catch (error) {
      setError('API error: ' + error.message);
    } finally {
  
    }
  };


  const totalData = async () => {
    try {
      const response = await fetch(dashboardUrl, {
        // const response = await fetch("http://192.168.1.36/backendci4/api/dashboard", {
        method: 'GET',
        headers: {
         token: token,
         cus_id: cusid,
         'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (response.ok) {
        setTotalData(data.data || []);
        // setFilteredShipment(data.data.slice(0,9));
      } else {
        setError('Error fetching data: ' + data.message);
      }
    } catch (error) {
      setError('API error: ' + error.message);
    } finally {
  
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = shipments.slice(indexOfFirstItem, indexOfLastItem).reverse();
  // const handlePageChange = (event, value) => {
  //   setCurrentPage(value);
  // };

  const totalCount = shipments.length;
  const codCount = shipments.filter(shipment => shipment.payment_mode === 'cod').length;
  const prepaidCount = shipments.filter(shipment => shipment.payment_mode === 'prepaid').length;
  const total = shipments.reduce((sum, shipment) => sum + parseFloat(shipment.total_amount), 0);
  

  const handleViewClick = (shipment) => {
    setSelectedShipment(shipment)
    setIsPopupOpen(true);
  };

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const data = [
    { name: 'Jan', shipments: 400, boxes: 240 },
    { name: 'Feb', shipments: 300, boxes: 139 },
    { name: 'Mar', shipments: 200, boxes: 980 },
    { name: 'Apr', shipments: 278, boxes: 390 },
    { name: 'May', shipments: 189, boxes: 480 },
    { name: 'Jun', shipments: 239, boxes: 380 },
    { name: 'Jul', shipments: 349, boxes: 430 },
  ];

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedRows([...Array(shipments.length).keys()]);
    } else {
      setSelectedRows([]);
    }
    setSelectAll(e.target.checked);
  };


  const handleRowSelect = (index) => {
    setSelectedRows((prev) => {
      const isSelected = prev.includes(index);
      if (isSelected) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };


  const handlePrintLabel = async (orderId) => {
    try {
      const response = await fetch(Base_Url + shipmentlabel, {
        method: 'POST',
        headers: {
          token,
          cus_id: cusid,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ order_id: orderId }), 
      });
  
      const data = await response.json();
        const printUrl = data?.DownlodeLink; 
        if (printUrl) {
            window.open(printUrl, '_blank'); 
            toast.success("shipmenmt label download sucessfully !")
        }
         else {
          toast.error(data.message || "url is not found please try again !");
        }
    } catch (error) {
      setError('API error: ' + error.message);
    }
  };
  return (
    <div className={`h-screen  flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
      <div className="z-10 ">
        <NavBar />
      </div>
        {
          profile_flag=== "0" && role != "admin"? <marquee className= " text-red-500 font-medium "> Your Kyc Is Pending Please Contact To The Support Team on support@pickupxpress.com</marquee> :""
          }
      <div className="flex flex-1 overflow-hidden">
      {isToggleSidebar && (
            <div className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
            style={
              window.innerWidth < 768 
                ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                : {}
            }>
              <Sidebar />
            </div>
          )}
        <div
          className={`flex-1  overflow-auto transition-all duration-300 ${isToggleSidebar ? 'md:ml-0' : 'md:ml-0'} ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:text-gray-800" : "bg-white"}`}>
          {/* DashBoard Content */}
          <div className="p-4 md:p-6 min-h-screen transition-all duration-300 rounded-custom">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
              {/* Card 1 */}
              <div className="bg-gradient-to-r from-[#06bb6d] to-[#036a3e] rounded-lg p-4 transform transition-transform">
                <p className="text-white text-lg font-sans">Today Orders</p>
                <p className="text-white text-4xl font-bold">{totaldata ?.total_order}</p>
                <p className="text-white text-sm font-sans">Last Month</p>
                <div className="flex justify-between items-center mt-2">
                  <FaUsers className="text-white text-2xl" />
                  <span className="text-white text-2xl">⋮</span>
                </div>
              </div>

              {/* Card 2 */}
              <div className="bg-gradient-to-r from-[#7f04a6] to-[#62059e] rounded-lg p-4 transform transition-transform">
                <p className="text-white text-lg font-sans">Total Delivered</p>
                <p className="text-white text-4xl font-bold">{totaldata?.total_delivered}</p>
                <p className="text-white text-sm font-sans">Last Month</p>
                <div className="flex justify-between items-center mt-2">
                  <FaSync className="text-white text-2xl" />
                  <span className="text-white text-2xl">⋮</span>
                </div>
              </div>

              {/* Card 3 */}
              <div className="bg-gradient-to-r from-[#a6042b] to-[#e81581] rounded-lg p-4 transform transition-transform">
                <p className="text-white text-lg font-sans">COD Orders</p>
                <p className="text-white text-4xl font-bold">{totaldata?.total_cod}</p>
                <p className="text-white text-sm font-sans">Last Month</p>
                <div className="flex justify-between items-center mt-2">
                  <FaCalendarAlt className="text-white text-2xl" />
                  <span className="text-white text-2xl">⋮</span>
                </div>
              </div>

              {/* Card 4 */}
              <div className="bg-gradient-to-r from-[#e85a15] to-[#a63904] rounded-lg p-4 transform transition-transform">
                <p className="text-white text-lg font-sans">Prepaid Orders</p>
                <p className="text-white text-4xl font-bold">{totaldata?.total_prepaid}</p>
                <p className="text-white text-sm font-sans">Last Month</p>
                <div className="flex justify-between items-center mt-2">
                  <FaRocket className="text-white text-2xl" />
                  <span className="text-white text-2xl">⋮</span>
                </div>
              </div>
            </div>

            {/* Card 5 - Total Sales */}
            <div className="bg-custom-gradient rounded-lg p-4 transform transition-transform">
              <div className="flex justify-between items-start">
                <div>
                  <p className="text-white text-lg font-bold">Total Sales</p>
                  <p className="text-white text-4xl font-bold">₹ {total}</p>
                  {/* <p className="text-white text-sm">₹ 00 In last month</p> */}
                </div>
                <span className="text-white text-2xl">⋮</span>
              </div>
              <div className="mt-4 h-48">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Line type="monotone" dataKey="shipments" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="boxes" stroke="#82ca9d" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>

            {/* Best Shipping Location */}
            <div className={`rounded-custom p-4 mb-6 mt-6 shadow-custom-light ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:text-white dark:shadow-custom-dark" : "bg-white"}`}>
              <h2 className="text-lg font-bold mb-4">Best Shipping Location</h2>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                <div>
                  <label className="block text-sm mb-1">Carrier</label>
                  <select className={`w-full p-2 border border-gray-300 rounded-custom text-sm ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:text-white" : "bg-white"}`}>
                    <option>All</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm mb-1">Status</label>
                  <select className={`w-full p-2 border border-gray-300 rounded-custom text-sm ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:text-white" : "bg-white"}`}>
                    <option>All</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm mb-1">Date</label>
                  <input type="date" className={`w-full p-2 border border-gray-300  rounded-custom  text-xs h-10  ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:text-white" : "bg-white"}`} />
                </div>
              </div>
            </div>

            {/* Table */}
            <div className={`rounded-custom p-4 ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)]  rounded-custom text-white" : "bg-white "}`}>
              <div className='overflow-x-auto'>
                <table className='min-w-full table-auto'>
                  <thead className='bg-[#0540a6dd] text-white text-[14px]'>
                    <tr>
                      <th className='p-2 text-left'>
                        <input
                          type="checkbox"
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      </th>
                      <th className='p-2 text-left'>OID</th>
                      <th className='p-2 text-left'>Partner</th>
                      <th className='p-2 text-left'>Products</th>
                      <th className='p-2 text-left'>WayBill No</th>
                      <th className='p-2 text-left'>Shipments</th>
                      <th className='p-2 text-left'>Status</th>
                      <th className='p-2 text-left'>Charges</th>
                      <th className='p-2 text-left'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((shipment, index) => (
                      <tr key={index} className='border-b'>
                        <td className='p-2'>
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(index)}
                            onChange={() => handleRowSelect(index)}
                          />
                        </td>
                        <td className='p-2'>{shipment.order_id}</td>
                        <td className='p-2'>{shipment.partner}</td>
                        <td className='p-2'>{shipment.products_desc}</td>
                        <td className='p-2'>{shipment.waybill}</td>
                        <td className='p-2'>{shipment.shipping_mode}</td>
                        <td className='p-2'>{shipment.status }</td>
                        <td className='p-2'>₹ {shipment.debit_amount || 'N/A'}</td>
                        <td className='p-2'>
                          <div className='flex space-x-2'>
                            <Button variant="contained" size="small"
                            onClick={() =>handleViewClick(shipment)}
                            color="primary"><IoMdEye /></Button>
                            {/* <Button variant="contained" size="small" color="secondary"><CiEdit /></Button> */}
                            <Button onClick={() => handlePrintLabel(shipment.order_id)}
                            variant="contained" size="small" color="success"><FcPrint /></Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <div className="flex justify-between items-center mt-4">
              <p>Showing <b>{indexOfFirstItem - 1 }</b> to <b>{Math.min(indexOfLastItem, filteredShipmemt.length)}</b> of <b>{filteredShipmemt.length}</b> results</p>
              <Pagination
                count={Math.ceil(filteredShipmemt.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  '.MuiPaginationItem-text': {
                    color: isDarkMode ? 'white' : 'defaultColor', 
                  },
                  '.MuiPaginationItem-root': {
                  },
                }}
                color="primary"
                size="small"
              />
            </div> */}
            </div>
          </div>
        </div>
      </div>
      <ViewAllShipment isopen={isPopupOpen} onClose={handleClose} shipment={selectedShipment} />
    </div>
  );
};

export default DashBoard;
