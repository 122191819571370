import React, { useState } from 'react';
import NavBar from '../../component/navBar/navBar';
import Sidebar from '../../component/sidebar/sidebar';
import { useMyContext } from '../../context/MyContext';
import '../../css/custom-scrollbar.css';
import {
    Truck,
    PackageCheck,
    Network,
    ShieldAlert,
    Webhook,
    ClipboardList,
    Crosshair,
    Target,
    BarChart3,
    Layers,
    ScanLine,
    Workflow
} from 'lucide-react';

const LogisticsServicePage = () => {
    const [activeTab, setActiveTab] = useState('services');
    const [hoveredService, setHoveredService] = useState(null);
    const { isToggleSidebar, isDarkMode } = useMyContext();

    const serviceCards = [
        {
            icon: <ClipboardList className="w-12 h-12 text-green-600" />,
            title: 'Order Confirmation',
            description: 'Streamlined order verification and confirmation process',
            features: [
                'Instant order validation',
                'Detailed order tracking',
                'Real-time status updates'
            ],
            bgGradient: 'from-green-100 via-green-50 to-white]',
        },
        {
            icon: <Webhook className="w-12 h-12 text-blue-600 transform rotate-45" />,
            title: 'API Integration',
            description: 'Seamless API connections for enhanced logistics management',
            features: [
                'Multiple API support',
                'Custom integration solutions',
                'Secure data exchange'
            ],
            bgGradient: 'from-blue-100 via-blue-50 to-white',
        },
        {
            icon: <Network className="w-12 h-12 text-purple-600" />,
            title: 'Channel Integration',
            description: 'Comprehensive multi-channel logistics coordination',
            features: [
                'Cross-platform synchronization',
                'Unified logistics management',
                'Streamlined communication channels'
            ],
            bgGradient: 'from-purple-100 via-purple-50 to-white',
        },
        {
            icon: <ShieldAlert className="w-12 h-12 text-yellow-600" />,
            title: 'NDR Management',
            description: 'Efficient handling of Non-Delivery Reports (NDR)',
            features: [
                'Automated NDR tracking',
                'Quick resolution strategies',
                'Minimized delivery failures'
            ],
            bgGradient: 'from-yellow-100 via-yellow-50 to-white',
        },
        {
            icon: <Crosshair className="w-12 h-12 text-red-600" />,
            title: 'Fraud Detection',
            description: 'Advanced fraud prevention and detection services',
            features: [
                'Real-time fraud monitoring',
                'AI-powered risk assessment',
                'Comprehensive security checks'
            ],
            bgGradient: 'from-red-100 via-red-50 to-white',
        }
    ];

    return (
        <div className={`h-screen  flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
            <div className="z-10 ">
                <NavBar />
            </div>
            <div className="flex flex-1 overflow-hidden">
                {isToggleSidebar && (
                    <div className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
                        style={
                            window.innerWidth < 768
                                ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                                : {}
                        }>
                        <Sidebar />
                    </div>
                )}
                <div className="container mx-auto mt-4 mb-4 ml-4 mr-4 p-4 custom-scrollbar dark:bg-[rgba(1,14,78,0.96)] rounded-custom dark:shadow-custom-dark shadow-custom-light overflow-y-auto">
                    <div className="bg-white dark:bg-[rgba(1,14,78,0.96)]  rounded-custom overflow-hidden">
                        <header className="bg-gradient-to-r from-customPurple to-purple-700 text-white py-6 px-6 shadow-lg">
                            <div className="max-w-7xl mx-auto flex justify-between items-center">
                                <div className="flex items-center space-x-4">
                                    <Truck className="w-12 h-12 animate-pulse" />
                                    <h1 className="text-3xl font-extrabold tracking-tight">PICKUPXPRESS LOGISTICS</h1>
                                    {/* <img
                                        src="assets/images/logo1.png"
                                        alt="PICKUPXPRESS LOGISTICS"
                                        className="h-15 w-20"
                                    /> */}
                                </div>
                                <nav className="flex space-x-2">
                                    <button
                                        className={`px-6 py-2 rounded-full transition-all duration-300 ${activeTab === 'services'
                                            ? 'bg-white text-blue-700 shadow-lg hover:bg-blue-50'
                                            : 'bg-white/20 hover:bg-white/30'
                                            }`}
                                        onClick={() => setActiveTab('services')}
                                    >
                                        Our Services
                                    </button>
                                </nav>
                            </div>
                        </header>
                        <main className="p-8">
                            {activeTab === 'services' && (
                                <div className="grid md:grid-cols-3 gap-8 ">
                                    {serviceCards.map((service, index) => (
                                        <div
                                            key={index}
                                            className={`
                                                bg-gradient-to-  ${service.bgGradient}
                                                rounded-custom p-6 
                                                shadow-custom-light
                                                dark:shadow-custom-dark
                                                transform transition-all duration-300 
                                                hover:-translate-y-2 hover:shadow-2xl 
                                                border border-transparent 
                                                hover:border-opacity-50
                                                relative overflow-hidden
                                                group
                                                dark:text-white
                                            `}
                                            onMouseEnter={() => setHoveredService(index)}
                                            onMouseLeave={() => setHoveredService(null)}
                                        >
                                            <div className="absolute inset-0 bg-white/10 opacity-0 group-hover:opacity-20 transition-opacity duration-300"></div>
                                            <div className="flex items-center mb-4">
                                                <div className={`
                                                    w-16 h-16 rounded-full 
                                                    flex items-center justify-center 
                                                    shadow-lg
                                                    dark:bg-[rgba(1,14,78,0.96)] dark:text-white rounded-custom dark:shadow-custom-dark shadow-custom-light 
                                                    transform transition-transform duration-300
                                                    ${hoveredService === index ? 'scale-110' : ''}
                                                `}>
                                                    {service.icon}
                                                </div>
                                                <h3 className="ml-4 text-2xl font-bold text-gray-800 dark:bg-[rgba(1,14,78,0.96)]  dark:text-white rounded-custom dark:shadow-custom-dark shadow-custom-light ">{service.title}</h3>
                                            </div>
                                            <p className="text-gray-600 dark:text-gray-300 mb-4 text-base leading-relaxed dark:bg-[rgba(1,14,78,0.96)]  dark:text-white rounded-custom dark:shadow-custom-dark shadow-custom-light ">
                                                {service.description}
                                            </p>
                                            <ul className="space-y-2 dark:bg-[rgba(1,14,78,0.96)]  dark:text-white rounded-custom dark:shadow-custom-dark shadow-custom-light ">
                                                {service.features.map((feature, featureIndex) => (
                                                    <li
                                                        key={featureIndex}
                                                        className="flex items-center text-gray-700 dark:text-white text-base"
                                                    >
                                                        <Target className="mr-3 w-5 h-5 text-blue-500 animate-pulse" />
                                                        {feature}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </main>

                        <footer className="bg-gradient-to-r from-customPurple to-purple-700 text-white py-8 px-6">
                            <div className="max-w-7xl mx-auto flex justify-between items-center">
                                <div>
                                    <h4 className="text-2xl font-bold mb-2">PICKUPXPRESS</h4>
                                    <p className="text-white/80">© 2024 All Rights Reserved</p>
                                </div>
                                <div className="flex space-x-6">
                                    <a
                                        href="#"
                                        className="hover:text-blue-200 transition-colors duration-300 text-base"
                                    >
                                        Contact
                                    </a>
                                    <a
                                        href="/help"
                                        className="hover:text-blue-200 transition-colors duration-300 text-base"
                                    >
                                        Support
                                    </a>
                                    <a
                                        href="#"
                                        className="hover:text-blue-200 transition-colors duration-300 text-base"
                                    >
                                        Privacy Policy
                                    </a>
                                </div>
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LogisticsServicePage;