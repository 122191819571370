import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { IoIosCloseCircle } from "react-icons/io";
import { zodResolver } from '@hookform/resolvers/zod';
import { useMyContext } from '../../context/MyContext';
import '../../css/custom-scrollbar.css';
import { FaUser } from "react-icons/fa";
import { Url_For_View_Document } from '../../config/config';
import { FaEye } from 'react-icons/fa'

const schema = z.object({
  Id: z.string()
    .min(1, "Order ID is required")
    .regex(/^\d+$/, "Order ID must be numeric"),
  Name: z.string()
    .min(1, "Name is required")
    .regex(/^[a-zA-Z\s]+$/, "Name must only contain alphabetic characters"),
  email: z.string()
    .email("Invalid email address"),
  phone: z.string()
    .length(10, "Phone number must be exactly 10 digits"),
  company_name: z.string().min(1, "Company name is required"),
  password: z.string().min(1, "Password is required"),
  status: z.enum(["0", "1"]).default("0"), // Assuming status is a string and "0" or "1" are valid options
  profile_flag: z.enum(["0", "1"]).default("0"), // Same as above
  user_address_image: z.string().url("Invalid URL for address image"),
  user_pancard_image: z.string().url("Invalid URL for PAN card image"),
  user_gst_image: z.string().url("Invalid URL for GST image"),
  aadhar_status: z.enum(["0", "1", "2"]).default("0"), // Assuming "0", "1", "2" are valid options
  pan_card_status: z.enum(["0", "1", "2"]).default("0"),
  gst_status: z.enum(["0", "1", "2"]).default("0"),
  aadhar_number: z.string()
    .regex(/^\d{12}$/, "Aadhar number must be 12 digits"),
  pancard_number: z.string()
    .regex(/^[A-Z]{5}\d{4}[A-Z]{1}$/, "Invalid PAN card format"),
  gst_number: z.string()
    .regex(/^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/, "Invalid GST number format"),
  company_type: z.string().min(1, "Company type is required"),
  role: z.string(), // Adjust roles as per requirements
  txn_amount: z.string().optional(), // Assuming this field can be empty
  wallet_status: z.enum(["0", "1"]).default("0"),
  create_date: z.string().refine(date => !isNaN(Date.parse(date)), {
    message: "Invalid create date format",
  }),
  timestamp: z.string().refine(timestamp => !isNaN(Date.parse(timestamp)), {
    message: "Invalid timestamp format",
  }),
});

const ViewAllCustomer = ({ isopen, onClose, customer }) => {
  const { isDarkMode } = useMyContext();
  const [isEditMode, setIsEditMode] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (customer) {
      reset({
        Id: customer.id || '',
        Name: customer.name || '',
        email: customer.email || '',
        phone: customer.phone || '',
        company_name: customer.company_name || '',
        company_type: customer.company_type || '',
        status: customer.status || '0',
        profile_flag: customer.profile_flag || '0',
        user_address_image: Url_For_View_Document + customer.user_address_image || '',
        user_pancard_image: Url_For_View_Document + customer.user_pancard_image || '',
        user_gst_image: Url_For_View_Document + customer.user_gst_image || '',
        aadhar_status: customer.aadhar_status || '0',
        pan_card_status: customer.pan_card_status || '0',
        gst_status: customer.gst_status || '0',
        aadhar_number: customer.aadhar_number || '',
        pancard_number: customer.pancard_number || '',
        gst_number: customer.gst_number || '',
        role: customer.role || '',
        txn_amount: customer.txn_amount || '',
        wallet_status: customer.wallet_status || '0',
        create_date: customer.create_date || '',
        timestamp: customer.timestamp || '',
      });
    }
  }, [customer, reset]);

  const onSubmit = (data) => {
    onClose();
  };

  const ViewDocument = (url) => {
    window.open(url, '_blank');
  }

  const handleClose = () => {
    setIsEditMode(false);
    onClose();
  };


  return (
    <Dialog open={isopen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle className="bg-customPurple text-white relative shadow-custom-light dark:shadow-custom-dark">
        <h2 className="text-xl sm:text-2xl font-bold text-white font-sans px-2 py-2 rounded-custom h-[40px] w-full sm:w-full flex items-center justify-center">
          <FaUser className="mr-2" size={24} />
          customer Details
        </h2>
        <IoIosCloseCircle
          className="absolute top-2 right-2 cursor-pointer"
          size={32}
          onClick={handleClose}
        />
      </DialogTitle>

      <DialogContent className="bg-white custom-scrollbar dark:bg-[rgba(1,14,78,0.96)]" style={{ maxHeight: '70vh' }}>
        <div className="p-4 m-4 bg-custom-background shadow-custom-light dark:bg-[rgba(1,14,78,0.96)] dark:shadow-custom-dark dark:text-white rounded-custom">
          <form className="space-y-6 font-sans">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">ID</label>
                <input
                  type="text"
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  {...register('Id')}
                  disabled={!isEditMode}
                />
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Name</label>
                <input
                  type="text"
                  {...register('Name')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                />
              </div>
            </div>

            {/* Contact Information */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Email</label>
                <input
                  type="email"
                  {...register('email')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                />
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Phone</label>
                <input
                  type="tel"
                  {...register('phone')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                />
              </div>
            </div>

            {/* Company Information */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Company Name</label>
                <input
                  type="text"
                  {...register('company_name')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                />
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Company Type</label>
                <input
                  type="text"
                  {...register('company_type')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                />
              </div>
            </div>

            {/* Status Fields */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Profile</label>
                <select
                  {...register('profile_flag')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                >
                  <option value="0">User Unverified</option>
                  <option value="1">User Varified</option>
                </select>
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">GST No</label>
                <input
                  type="text"
                  {...register('gst_number')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                />
              </div>
            </div>

            {/* Document Numbers */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Aadhar Number</label>
                <input
                  type="text"
                  {...register('aadhar_number')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                />
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">PAN Card Number</label>
                <input
                  type="text"
                  {...register('pancard_number')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                />
              </div>
            </div>
            {/* 
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">Aadhar Card Image</label>
                <button
                  type="button"
                  className="mt-2 text-sm text-blue-600 hover:text-blue-700 font-semibold"
                  onClick={() => ViewDocument(Url_For_View_Document + customer.user_address_image)}
                >
                  <FaEye size={20} />
                </button>
                <input
                  type="text"
                  {...register('user_address_image')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                />
              </div>
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">PAN Card Image</label>
                <button
                  type="button"
                  className="mt-2 text-sm text-blue-600 hover:text-blue-700 font-semibold"
                  onClick={() => ViewDocument(Url_For_View_Document + customer.user_pancard_image)}
                >
                 <FaEye size={20} />
                </button>
                <input
                  type="text"
                  {...register('user_pancard_image')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm ml-2 font-bold text-black dark:text-white">GST Image</label>
                <button
                  type="button"
                  className="mt-2 text-sm text-blue-600 hover:text-blue-700 font-semibold"
                  onClick={() => ViewDocument(Url_For_View_Document + customer.user_gst_image)}
                >
                  <FaEye size={20} />
                </button>
                <input
                  type="text"
                  {...register('user_gst_image')}
                  className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
                  disabled={!isEditMode}
                />
              </div>
            </div> */}

            <div className="flex items-center justify-between">
              <label className="text-sm font-bold text-black dark:text-white flex-1">Aadhar Card Image</label>
              <button
                type="button"
                className="ml-2 text-sm text-blue-600 hover:text-blue-700 font-semibold"
                onClick={() => ViewDocument(Url_For_View_Document + customer.user_address_image)}
              >
                <FaEye size={20} />
              </button>
            </div>

            <input
              type="text"
              {...register('user_address_image')}
              className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
              disabled={!isEditMode}
            />



            <div className="flex items-center justify-between">
              <label className="text-sm font-bold text-black dark:text-white flex-1">Pan Card Image</label>
              <button
                type="button"
                className="ml-2 text-sm text-blue-600 hover:text-blue-700 font-semibold"
                onClick={() => ViewDocument(Url_For_View_Document + customer.user_pancard_image)}
              >
                <FaEye size={20} />
              </button>
            </div>

            <input
              type="text"
              {...register('user_pancard_image')}
              className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
              disabled={!isEditMode}
            />


            <div className="flex items-center justify-between">
              <label className="text-sm font-bold text-black dark:text-white flex-1">GST Image</label>
              <button
                type="button"
                className="ml-2 text-sm text-blue-600 hover:text-blue-700 font-semibold"
                onClick={() => ViewDocument(Url_For_View_Document + customer.user_gst_image)}
              >
                <FaEye size={20} />
              </button>
            </div>

            <input
              type="text"
              {...register('user_gst_image')}
              className={`mt-2 p-2 border w-full rounded-custom focus:ring-1 focus:ring-customPurple ${isDarkMode ? "dark:bg-[rgba(1,14,78,0.96)] dark:placeholder-white text-white" : ""}`}
              disabled={!isEditMode}
            />





            <div className="flex justify-start gap-4">
              <button
                onClick={() => setIsEditMode(!isEditMode)}
                className="bg-customPurple text-white py-2 px-4 rounded-custom hover:bg-customPurpleHover focus:outline-none focus:ring-2 focus:ring-customPurple focus:ring-opacity-50"
                type="button"
              >
                {isEditMode ? 'Cancel' : 'Edit'}
              </button>

              {isEditMode && (
                <button
                  type="submit"
                  className="bg-customPurple text-white py-2 px-4 rounded-custom hover:bg-customPurpleHover focus:outline-none focus:ring-2 focus:ring-customPurple focus:ring-opacity-50"
                >
                  Save
                </button>
              )}
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ViewAllCustomer;
