import React from 'react';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useMyContext } from '../../../context/MyContext';
import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { z } from 'zod';
import Sidebar from '../../../component/sidebar/sidebar';
import NavBar from '../../../component/navBar/navBar';
import ViewRateCalculator from '../../../component/models/ViewRateCalculator';
import '../../../css/custom-scrollbar.css';
import { Base_Url, priceEstimator, getpincode } from '../../../config/config';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../component/spinner/spinner';
import ViewRateForword from '../../../component/models/ViewRateForwordShipmet';
import { TbJetpackFilled } from 'react-icons/tb';

const rateCalculatorSchema = z.object({
  pickupPincode: z.string()
    .length(6, "pickUp pincode must be exactly 6 digits")
    .regex(/^\d+$/, "pickUp pincode must be numeric"),
  dropPincode: z.string()
    .length(6, "DropPincode must be exactly 6 digits")
    .regex(/^\d+$/, "DropPincode  must be numeric"),
  weight: z.string()
    .min(1, "weight is required")
    .regex(/^\d*\.?\d+$/, "weight must be a positive number and can be a float"),
  length: z.string()
    .min(1, "Length is required")
    .regex(/^\d*\.?\d+$/, "Length must be a positive number and can be a float"),
  width: z.string()
    .min(1, "Width is required")
    .regex(/^\d*\.?\d+$/, "Width must be a positive number and can be a float"),
  height: z.string()
    .min(1, "Height is required")
    .regex(/^\d*\.?\d+$/, "Height must be a positive number and can be a float"),
  shipmentType: z.string().optional(),
  declaredValue: z
    .string()
    .optional()
    .refine(value => value === "" || (!isNaN(Number(value)) && Number(value) >= 0), {
      message: "Declared Value must be a non-negative number",
    }),
  prepaid: z.string().nonempty({ message: "Prepaid/COD is required" }),
  cod: z.string().optional(),
});

const RateCalculator = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [data, setData] = useState(null);
  const [prepaidValue, setPrepaidValue] = useState('');
  const { isDarkMode, isToggleSidebar } = useMyContext();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [pickupCity, setPickupCity] = useState('');
  const [dropCity, setDropCity] = useState('');


  const handlePrepaidChange = (e) => {
    setPrepaidValue(e.target.value);
  };
  const handlePincodeChange = (e, type) => {
    const { value } = e.target;
    const filteredValue = value.replace(/[^0-9]/g, '');
    setValue(type === 'pickup' ? 'pickupPincode' : 'dropPincode', filteredValue);
    if (filteredValue.length === 6) {
      clearErrors(type === 'pickup' ? 'pickupPincode' : 'dropPincode');
    }

    // Clear city whenever the pincode changes
    clearCity(type);

    if (filteredValue.length === 6) {
      fetchPincodeData(filteredValue, type)
    }
  };
  const handleNavagation = () => {
    navigate("/dashboard");
  }

  const handleViewClick = () => {
    setIsPopupOpen(true);
  };
  const handleClose = () => {
    setIsPopupOpen(false);
  };
  const token = localStorage.getItem('token');
  const cusid = localStorage.getItem('cus_id');
  const { register, handleSubmit, formState: { errors }, reset, watch, setValue, clearErrors } = useForm({
    resolver: zodResolver(rateCalculatorSchema),
  });

  const onSubmit = async (data) => {
    setLoader(true);
    const requestBody = {
      from_pincode: data.pickupPincode,
      to_pincode: data.dropPincode,
      length: parseFloat(data.length),
      width: parseFloat(data.width),
      height: parseFloat(data.height),
      weight: parseFloat(data.weight) / 1000,
      shipment_type: data.shipmentType,
      declared_value: data.declaredValue ? parseFloat(data.declaredValue) : "",
      prepaid: data.prepaid,
      cod_amount: data.prepaid === 'cod' ? parseFloat(data.cod) : "",
    };

    try {
      const response = await axios.post(Base_Url + priceEstimator, requestBody, {
        headers: {
          token,
          cus_id: cusid,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      if (response.data.status === "success") {
        setData(response.data);
        reset();
        handleViewClick();
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoader(false);
    }
  };
  const clearCity = (type) => {
    if (type === 'pickup') {
      setPickupCity('');
    } else {
      setDropCity('');
    }
  }  
  const pickupPincode = watch('pickupPincode');
  const dropPincode = watch('dropPincode');


  useEffect(() => {
    if (pickupPincode && pickupPincode.length === 6) {
      fetchPincodeData(pickupPincode, 'pickup');
    } else {
      clearCity('pickup');
    }
  }, [pickupPincode]);

  useEffect(() => {
    if (dropPincode && dropPincode.length === 6) {
      fetchPincodeData(dropPincode, 'drop');
    } else {
      clearCity('drop');
    }
  }, [dropPincode])


  const fetchPincodeData = async (pincode, type) => {
    const token = localStorage.getItem('token');
    const cus_id = localStorage.getItem('cus_id');
    const urlgetPincode = `${Base_Url}${getpincode}?pincode=${pincode}`;
    try {
      const response = await fetch(urlgetPincode, {
        method: 'GET',
        headers: {
          'token': token,
          'cus_id': cus_id,
          'Content-Type': 'application/json',
        },
      });

      const data = await response.json();
      if (data && data.status === "SUCCESS") {
        let { city } = data.data[0];
        city = city.charAt(0).toUpperCase() + city.slice(1).toLowerCase();
        if (type === 'pickup') {
          setPickupCity(city);
        } else {
          setDropCity(city);
        }
      } else {
        clearCity(type);
        toast.error("Failed to fetch city data");
      }
    } catch (error) {
      console.error('Error fetching pincode data:', error);
      toast.error("Error fetching pincode data");
    }
  };

  return (
    <div className={`h-screen flex flex-col overflow-hidden ${isDarkMode ? 'dark:bg-[rgba(1,14,78,0.96)]' : 'bg-white'}`}>
      <div className="z-10">
        <NavBar />
      </div>

      <div className="flex flex-1 overflow-hidden">
        {isToggleSidebar && (
          <div className={`fixed z-20 h-full w-64 md:relative duration-300 bg-gray-200 dark:bg-[rgba(1,14,78,0.96)] dark:text-white md:bg-transparent mt-4 md:w-64 md:z-20`}
            style={
              window.innerWidth < 768
                ? { top: "113px", height: "calc(100vh - 115px)", overflowY: 'auto' }
                : {}
            }>

            <Sidebar />
          </div>
        )}

        <div className="container mx-auto p-4 flex flex-wrap justify-center relative overflow-y-auto custom-scrollbar ">
          <div className=" w-full md:w-[40%] text-custom-gray font-sans p-4 md:p-8 max-w-4xl mx-auto dark:text-white dark:shadow-custom-dark shadow-custom-light dark:bg-[rgba(1,14,78,0.96)] rounded-custom">
            <button onClick={handleNavagation}
              className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 bg-customPurple hover:bg-purple-700 text-white font-sans px-2 sm:px-4 py-2 sm:py-1 rounded h-[40px] w-[60%] sm:w-full flex items-center justify-center">


              Rate Calculator
            </button>
            <form className="space-y-6 font-semibold" onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 dark:text-white">
                <div>
                  <label htmlFor="pickupPincode" className="block text-sm mb-1">Pickup Pincode*</label>
                  <input
                    type="text"
                    maxLength="6"
                    id="pickupPincode"
                    className={`w-full px-3 py-2 shadow-custom-light rounded-custom dark:shadow-custom-dark dark:text-white dark:bg-[rgba(1,14,78,0.96)] ${errors.pickupPincode ? 'border-red-500' : ''}`}
                    {...register('pickupPincode')}
                    onChange={(e) => handlePincodeChange(e, 'pickup')}
                  />
                  {errors.pickupPincode ? (
                    <p className="text-red-500 text-sm mt-1">{errors.pickupPincode.message}</p>
                  ) : (
                    pickupCity && <p className="text-sm  text-customPurple mt-2">Pickup City: {pickupCity}</p>
                  )}
                </div>
                <div>
                  <label htmlFor="dropPincode" className="block text-sm mb-1">Drop Pincode*</label>
                  <input
                    type="text"
                    id="dropPincode"
                    maxLength="6"
                    className={`w-full px-3 py-2 shadow-custom-light rounded-custom dark:shadow-custom-dark dark:text-white dark:bg-[rgba(1,14,78,0.96)] ${errors.dropPincode ? 'border-red-500' : ''}`}
                    {...register('dropPincode')}
                    onChange={(e) => handlePincodeChange(e, 'drop')}
                  />
                  {errors.dropPincode ? (
                    <p className="text-red-500 text-sm mt-1">{errors.dropPincode.message}</p>
                  ) : (
                    dropCity && <p className="text-sm text-customPurple mt-2">Drop City: {dropCity}</p>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 dark:text-white">
                <div>
                  <label className="block text-sm mb-1">Dimensions (in Cms)*</label>
                  <div className="grid grid-cols-3 gap-2">
                    <input
                      type="number"
                      id='length'
                      step="0.01"
                      className={`w-full px-3 py-2 shadow-custom-light rounded-custom dark:shadow-custom-dark dark:text-white dark:bg-[rgba(1,14,78,0.96)] ${errors.length ? 'border-red-500' : ''}`}
                      placeholder="L"
                      {...register('length')}
                    />
                    <input
                      type="number"
                      step="0.01"
                      id='width'
                      className={`w-full px-3 py-2 shadow-custom-light rounded-custom dark:shadow-custom-dark dark:text-white dark:bg-[rgba(1,14,78,0.96)] ${errors.width ? 'border-red-500' : ''}`}
                      placeholder="w"
                      {...register('width')}
                    />
                    <input
                      type="number"
                      id='height'
                      className={`w-full px-3 py-2 shadow-custom-light rounded-custom dark:shadow-custom-dark dark:text-white dark:bg-[rgba(1,14,78,0.96)] ${errors.height ? 'border-red-500' : ''}`}
                      placeholder="H"
                      step="0.01"
                      {...register('height')}
                    />
                  </div>
                  {errors.length && <p className="text-red-500 text-sm mt-1">{errors.length.message}</p>}
                </div>
                <div>
                  <label htmlFor="weight" className="block text-sm mb-1">Weight (in Grms)*</label>
                  <input
                    id="weight"
                    type="number"
                    step="0.01"
                    className={`w-full px-3 py-2 shadow-custom-light rounded-custom dark:shadow-custom-dark dark:text-white dark:bg-[rgba(1,14,78,0.96)] ${errors.weight ? 'border-red-500' : ''}`}
                    {...register('weight')}
                  />
                  {errors.weight && <p className="text-red-500 text-sm mt-1">{errors.weight.message}</p>}
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 dark:text-white">
                <div>
                  <label htmlFor="shipmentType" className="block text-sm mb-1">Shipment Type</label>
                  <select
                    id="shipmentType"
                    className={`w-full px-3 py-2 shadow-custom-light rounded-custom dark:shadow-custom-dark dark:text-white dark:bg-[rgba(1,14,78,0.96)] ${errors.shipmentType ? 'border-red-500' : ''}`}
                    {...register('shipmentType')}
                  >
                    <option value="">Select Shipment Type</option>
                    <option value="Forward">Forward</option>
                  </select>
                  {errors.shipmentType && <p className="text-red-500 text-sm mt-1">{errors.shipmentType.message}</p>}
                </div>
                <div>
                  <label htmlFor="declaredValue" className="block text-sm mb-1">Declared Value</label>
                  <input
                    type="text"
                    id="declaredValue"
                    className={`w-full px-3 py-2 shadow-custom-light rounded-custom dark:shadow-custom-dark dark:text-white dark:bg-[rgba(1,14,78,0.96)] ${errors.declaredValue ? 'border-red-500' : ''}`}
                    {...register('declaredValue')}
                  />
                  {errors.declaredValue && <p className="text-red-500 text-sm mt-1">{errors.declaredValue.message}</p>}
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 dark:text-white">
                <div>
                  <label htmlFor="prepaid" className="block text-sm mb-1">Prepaid/COD*</label>
                  <select
                    id="prepaid"
                    className={`w-full px-3 py-2 shadow-custom-light rounded-custom dark:shadow-custom-dark dark:text-white dark:bg-[rgba(1,14,78,0.96)] ${errors.prepaid ? 'border-red-500' : ''}`}
                    {...register('prepaid')}
                    onChange={handlePrepaidChange}
                  >
                    <option value="">Select Prepaid/COD</option>
                    <option value="prepaid">Prepaid</option>
                    <option value="cod">COD</option>
                  </select>
                  {errors.prepaid && <p className="text-red-500 text-sm mt-1">{errors.prepaid.message}</p>}
                </div>
                {prepaidValue === 'cod' && (
                  <div>
                    <label htmlFor="cod" className="block text-sm mb-1">COD Amount*</label>
                    <input
                      id="cod"
                      type="text"
                      className={`w-full px-3 py-2 shadow-custom-light rounded-custom dark:shadow-custom-dark dark:text-white dark:bg-[rgba(1,14,78,0.96)] ${errors.codAmount ? 'border-red-500' : ''}`}
                      {...register('cod')}
                    />
                    {errors.cod && <p className="text-red-500 text-sm mt-1">{errors.cod.message}</p>}
                  </div>
                )}
              </div>
              <div className="text-center">
                <button type="submit" className="bg-customPurple text-white px-6 py-2 rounded-md hover:bg-purple-700">
                  {loader ? <Spinner /> : "Submit"}
                </button>
              </div>
            </form>
          </div>
          <div className="w-full overflow-hidden md:w-1/2 md:block ml-0  md:mr-9 md:ml-16 relative shadow-custom-light dark:shadow-custom-dark rounded-custom  items-center justify-center">
            <div className="absolute  top-0 h-full w-full ">
              <img
                src="/assets/images/ratecalculator.png"
                alt="Rate Calculator"
                className="w-full h-auto text-purple-600 "
              />
            </div>
          </div>
        </div>
      </div>
      <ViewRateCalculator isopen={isPopupOpen} onClose={handleClose} data={data} />
    </div>
  );
};

export default RateCalculator;
